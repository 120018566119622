import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import type { BreadcrumbProps } from 'antd/es/breadcrumb'
import { Icon } from "../../setters";
import React from 'react'

export interface FormilyBreadcrumbProps extends BreadcrumbProps {
  menu?: any
}

const CustomBreadCrumb = ({ menu=[], ...props}: FormilyBreadcrumbProps) => {
  const items = menu.map((item, index) => {
    return {
      key: item.key || index,
      label: item.label,
      icon: item.icon && <Icon name={item.icon} />
    }
  })
  

  return <AntdBreadcrumb {...props} >
    {
      items.map(item => {
        return <AntdBreadcrumb.Item key={item.key}>{item.icon}{!!item.icon && !!item.label ? ' ' : ''}{item.label}</AntdBreadcrumb.Item>
      })
    }
  </AntdBreadcrumb>
}

const FormilyBreadcrumb: ReactFC<FormilyBreadcrumbProps> = connect(
  CustomBreadCrumb,
)

export default FormilyBreadcrumb
