import { ISchema } from '@formily/react'

export const Ellipsis: ISchema = {
  type: "object",
  properties: {
    content: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input.TextArea",
      "x-component-props": {
        minRows: 4,
        autoSize: true
      }
    },
    rows: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 1
      },
    },
    collapseText: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: '',
        placeholder: '请输入'
      },
    },
    expandText: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: '',
        placeholder: '请输入'
      },
    },
    defaultExpanded: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultValue: false,
      },
    },
  },
};

