import { ISchema } from '@formily/react'
import { ColumnsSetter, ActionsSetter, DataSourceSetter, StaticsSetter } from '../setters'

export const ProTable: ISchema = {
  type: 'object',
  properties: {
    columns: {
      title: '表头数据',
      type: 'array',
      "x-decorator": 'FormItem',
      "x-component": ColumnsSetter
    },
    actions: {
      title: '操作栏',
      type: 'array',
      "x-decorator": 'FormItem',
      "x-component": ActionsSetter
    },
    dataSource: {
      title: '表格数据',
      type: 'array',
      "x-decorator": 'FormItem',
      "x-component": DataSourceSetter
    },
    staticsProps: {
      title: '统计数据',
      type: 'object',
      "x-decorator": 'FormItem',
      "x-component": StaticsSetter
    },
    size: {
      title: '表格大小',
      "x-decorator": 'FormItem',
      "x-component": 'Select',
      "x-component-props": {
        defaultValue: 'default',
      }
    },
    bordered: {
      type: 'boolean',
      "x-decorator": 'FormItem',
      "x-component": 'Switch'
    },
    showPagination: {
      type: 'boolean',
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    // pageSize: {
    //   type: 'string',
    //   "x-decorator": 'FormItem',
    //   "x-component": 'Input',
    //   "x-reactions": {
    //     dependencies: ['.showPagination'],
    //     fulfill: {
    //       state: {
    //         visible: "{{!!$deps[0]}}",
    //       }
    //     }
    //   },
    //   "x-component-props": {
    //     placeholder: '请输入数字'
    //   },
    //   format: 'number'
    // },
    isScroll: {
      type: 'boolean',
      "x-decorator": 'FormItem',
      title: '是否滚动',
      "x-component": 'Switch',
      "x-component-props": {
        defaultChecked: false
      }
    },
    scrollY: {
      type: 'string',
      "x-decorator": 'FormItem',
      "x-component": 'SizeInput',
      "x-component-props": {
        include: ['px','vh'],
        allowClear: true
      },
      "x-reactions": [
        {
          dependencies: ['.isScroll'],
          fulfill: {
            state: {
              visible: '{{!!$deps[0]}}'
            }
          }
        }
      ]
    },
    showSelection: {
      type: 'boolean',
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    selectionType: {
      type: 'string',
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      enum: [
        {
          label: '多选',
          value: 'checkbox',
        },
        {
          label: '单选',
          value: 'radio',
        },
      ],
      "x-reactions": {
        dependencies: ['.showSelection'],
        fulfill: {
          state: {
            visible: "{{!!$deps[0]}}",
            initialValue: 'checkbox'
          }
        }
      }
    },
  },
}

