import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyProgressBar, { FormilyProgressBarProps } from "./ProgressBar"
import FormilyProgressCircle, { FormilyProgressCircleProps } from "./ProgressCircle";
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import "./styles.less";

export const ProgressBar: DnFC<FormilyProgressBarProps> = FormilyProgressBar

ProgressBar.Behavior = createBehavior({
  name: "ProgressBar",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "ProgressBar",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.ProgressBar),
  },
  designerLocales: AllLocales.Progress.bar,
});

ProgressBar.Resource = createResource({
  icon: "ProgressBarSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "ProgressBar",
        "x-component-props": {
          percent: 50,
          style: {
            padding: '10px 0px 10px 0px'
          }
        },
      },
    },
  ],
});

export const ProgressCircle: DnFC<FormilyProgressCircleProps> = FormilyProgressCircle

ProgressCircle.Behavior = createBehavior({
  name: "ProgressCircle",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "ProgressCircle",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.ProgressCircle),
  },
  designerLocales: AllLocales.Progress.circle,
});

ProgressCircle.Resource = createResource({
  icon: "ProgressCircleSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "ProgressCircle",
        "x-component-props": {
          percent: 50,
          style: {
            '--size': '120px'
          }
        },
      },
    },
  ],
});