import { ISchema } from '@formily/react'


export const ProgressBar: ISchema = {
  type: "object",
  properties: {
    percent: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": 'NumberPicker',
      "x-component-props": {
        min: 0,
        max: 100
      }
    },
    rounded: {
      type: 'boolean',
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultChecked: true
      }
    },
    text: {
      type: 'boolean',
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    "style.--fill-color": {
      title: '填充颜色',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "ColorInput",
      "x-component-props": {
        defaultValue: "#1890ff",
      },
    },
    "style.--track-width": {
      title: '进度条宽度',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        defaultValue: "8px",
        include: ['px']
      },
    },
    "style.--text-width": {
      title: '文字宽度',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        defaultValue: "40px",
        include: ['px']
      },
    }
  },
};

export const ProgressCircle: ISchema = {
  type: "object",
  properties: {
    percent: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": 'NumberPicker',
      "x-component-props": {
        min: 0,
        max: 100
      }
    },
    "style.--fill-color": {
      title: '填充颜色',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "ColorInput",
      "x-component-props": {
        defaultValue: "#1890ff",
      },
    },
    "style.--track-width": {
      title: '进度条宽度',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        defaultValue: "3",
        include: ['px']
      },
    },
    "style.--size": {
      title: '画布宽度',
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        defaultValue: "50",
        include: ['px']
      },
    }
  },
};

