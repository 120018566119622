export const Affix = {
  'zh-CN': {
    title: '固钉',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
        type: '吸附位置'
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
}
