import React from 'react'
import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Slider as AntdSlider } from 'antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

import type { SliderProps } from 'antd-mobile/es'

interface FormilySliderProps extends SliderProps {}
const FormilySlider: ReactFC<FormilySliderProps> = connect(
  AntdSlider,
)

export const Slider: DnFC<React.ComponentProps<typeof AntdSlider>> = (props) => {
  const marks = {
    0: 0,
    20: 20,
    40: 40,
    60: 60,
    80: 80,
    100: 100,
  };
  return <FormilySlider marks={props.ticks ? marks: {}} { ...props } />
}

Slider.Behavior = createBehavior({
  name: 'Slider',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Slider',
  designerProps: {
    propsSchema: createFieldSchema(AllSchemas.Slider),
  },
  designerLocales: AllLocales.Slider,
})

Slider.Resource = createResource({
  icon: 'SliderSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'boolean',
        title: 'Slider',
        'x-decorator': 'FormItem',
        'x-component': 'Slider',
      },
    },
  ],
})
