import { ISchema } from '@formily/react'
import { ItemSetter, IconSelect } from '../setters'
import { treeToArr } from '../../service'

export const Menu: ISchema = {
  type: 'object',
  properties: {
    items: {
      type: 'array',
      'x-decorator': 'FormItem',
      'x-component': ItemSetter,
      "x-component-props": {
        allowExtendOption: false
      }
    },
    mode: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      'x-component-props': {
        defaultValue: 'horizontal',
        optionType: 'button',
      },
    },
    theme:  {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      'x-component-props': {
        defaultValue: 'light',
        optionType: 'button',
      },
    },
    defaultChecked: {
      title: '默认选中',
      type: 'string',
      "x-decorator": 'FormItem',
      "x-component": 'Select',
      enum: [],
      "x-component-props": {
        placeholder: '请选择',
        allowClear: true,
        fieldNames: {
          label: 'label',
          value: 'key',
        },
      },
      "x-reactions": (field) => {
        const items = field.query('.items').value()
        if (Array.isArray(items)) {
          field.setDataSource(treeToArr(items))
        }
      }
    },
    // icon: {
    //   type: 'string',
    //   title: '图标',
    //   "x-decorator": "FormItem",
    //   "x-component": IconSelect,
    //   "x-component-props": {
    //     placeholder: '请选择',
    //   },
    // }
  },
}
