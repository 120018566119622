import { ISchema } from '@formily/react'
import { ItemSetter } from '../setters'

export const Breadcrumb: ISchema = {
  type: "object",
  properties: {
    separator: {
      type: "string",
      title: "分隔符",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: "/",
        placeholder: "请输入分隔符"
      }
    },
    menu: {
      type: 'array',
      'x-decorator': 'FormItem',
      'x-component': ItemSetter,
      "x-component-props": {
        // allowExtendOption: false,
        allowTree: false,
        defaultOptionValue: [
          {
            label: 'label',
            value: `主页`,
          },
          {
            label: 'icon',
            value: '',
          }
        ]
      }
    },
  },
};
