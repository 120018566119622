import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Icon } from '../../setters'
import React from 'react'

export interface FormilyIconProps {
  name: string,
  style?: React.CSSProperties,
  rotate?: number
  spin?: boolean
}
const FormilyIcon: ReactFC<FormilyIconProps> = connect(
  Icon,
)

export default FormilyIcon
