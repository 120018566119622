import React from 'react'
import { Stepper as AntdSwitch } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const Stepper: DnFC<React.ComponentProps<typeof AntdSwitch>> = AntdSwitch

Stepper.Behavior = createBehavior({
  name: 'Stepper',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Stepper',
  designerProps: {
    propsSchema: createFieldSchema(),
  },
  designerLocales: {
    'zh-CN': {
      title: '步进器',
    }
  },
})

Stepper.Resource = createResource({
  icon: 'StepperSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'boolean',
        title: 'Stepper',
        'x-decorator': 'FormItem',
        'x-component': 'Stepper',
      },
    },
  ],
})
