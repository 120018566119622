import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { NavBar, NavBarProps } from 'antd-mobile'
import React from 'react'
import { clickEventToProps } from '../../../service/utils'

export interface FormilyNavBarProps extends NavBarProps {
  clickEventProps?: any
}

export const FormilyNavBar = ({ clickEventProps, ...props}: FormilyNavBarProps) => {
  let clickProps;
  if(clickEventProps) {
    clickProps = clickEventToProps(clickEventProps, 'onBack');
  }
  return <NavBar {...props} {...clickProps} ></NavBar>
}

export const AntdNavBar: ReactFC<FormilyNavBarProps> = connect(
  NavBar,
)

export default AntdNavBar
