// icon分类数据整合
// 方向性图标-线框风格
export const directionOutlined: string[] = [
  "StepBackwardOutlined",
  "StepForwardOutlined",
  "FastBackwardOutlined",
  "FastForwardOutlined",
  "ShrinkOutlined",
  "ArrowsAltOutlined",
  "DownOutlined",
  "UpOutlined",
  "LeftOutlined",
  "RightOutlined",
  "CaretUpOutlined",
  "CaretDownOutlined",
  "CaretLeftOutlined",
  "CaretRightOutlined",
  "UpCircleOutlined",
  "DownCircleOutlined",
  "LeftCircleOutlined",
  "RightCircleOutlined",
  "DoubleRightOutlined",
  "DoubleLeftOutlined",
  "VerticalLeftOutlined",
  "VerticalRightOutlined",
  "VerticalAlignTopOutlined",
  "VerticalAlignMiddleOutlined",
  "VerticalAlignBottomOutlined",
  "ForwardOutlined",
  "BackwardOutlined",
  "RollbackOutlined",
  "EnterOutlined",
  "RetweetOutlined",
  "SwapOutlined",
  "SwapLeftOutlined",
  "SwapRightOutlined",
  "ArrowUpOutlined",
  "ArrowDownOutlined",
  "ArrowLeftOutlined",
  "ArrowRightOutlined",
  "PlayCircleOutlined",
  "UpSquareOutlined",
  "DownSquareOutlined",
  "LeftSquareOutlined",
  "RightSquareOutlined",
  "LoginOutlined",
  "LogoutOutlined",
  "MenuFoldOutlined",
  "MenuUnfoldOutlined",
  "BorderBottomOutlined",
  "BorderHorizontalOutlined",
  "BorderInnerOutlined",
  "BorderOuterOutlined",
  "BorderLeftOutlined",
  "BorderRightOutlined",
  "BorderTopOutlined",
  "BorderVerticleOutlined",
  "PicCenterOutlined",
  "PicLeftOutlined",
  "PicRightOutlined",
  "RadiusBottomleftOutlined",
  "RadiusBottomrightOutlined",
  "RadiusUpleftOutlined",
  "RadiusUprightOutlined",
  "FullscreenOutlined",
  "FullscreenExitOutlined"
]

// 提示建议性图标-线框风格
export const suggestionOutlined: string[] = [
  "QuestionOutlined",
  "QuestionCircleOutlined",
  "PlusOutlined",
  "PlusCircleOutlined",
  "PauseOutlined",
  "PauseCircleOutlined",
  "MinusOutlined",
  "MinusCircleOutlined",
  "PlusSquareOutlined",
  "MinusSquareOutlined",
  "InfoOutlined",
  "InfoCircleOutlined",
  "ExclamationOutlined",
  "ExclamationCircleOutlined",
  "CloseOutlined",
  "CloseCircleOutlined",
  "CloseSquareOutlined",
  "CheckOutlined",
  "CheckCircleOutlined",
  "CheckSquareOutlined",
  "ClockCircleOutlined",
  "WarningOutlined",
  "IssuesCloseOutlined",
  "StopOutlined"
]

// 编辑类图标-线框风格
export const editorOutlined: string[] = [
  "EditOutlined",
  "FormOutlined",
  "CopyOutlined",
  "ScissorOutlined",
  "DeleteOutlined",
  "SnippetsOutlined",
  "DiffOutlined",
  "HighlightOutlined",
  "AlignCenterOutlined",
  "AlignLeftOutlined",
  "AlignRightOutlined",
  "BgColorsOutlined",
  "BoldOutlined",
  "ItalicOutlined",
  "UnderlineOutlined",
  "StrikethroughOutlined",
  "RedoOutlined",
  "UndoOutlined",
  "ZoomInOutlined",
  "ZoomOutOutlined",
  "FontColorsOutlined",
  "FontSizeOutlined",
  "LineHeightOutlined",
  "DashOutlined",
  "SmallDashOutlined",
  "SortAscendingOutlined",
  "SortDescendingOutlined",
  "DragOutlined",
  "OrderedListOutlined",
  "UnorderedListOutlined",
  "RadiusSettingOutlined",
  "ColumnWidthOutlined",
  "ColumnHeightOutlined"
]

// 数据类图标-线框风格
export const dataOutlined: string[] = [
  "AreaChartOutlined",
  "PieChartOutlined",
  "BarChartOutlined",
  "DotChartOutlined",
  "LineChartOutlined",
  "RadarChartOutlined",
  "HeatMapOutlined",
  "FallOutlined",
  "RiseOutlined",
  "StockOutlined",
  "BoxPlotOutlined",
  "FundOutlined",
  "SlidersOutlined"
]

// 品牌类图标-线框风格
export const logoOutlined: string[] = [
  "AndroidOutlined",
  "AppleOutlined",
  "WindowsOutlined",
  "IeOutlined",
  "ChromeOutlined",
  "GithubOutlined",
  "AliwangwangOutlined",
  "DingdingOutlined",
  "WeiboSquareOutlined",
  "WeiboCircleOutlined",
  "TaobaoCircleOutlined",
  "Html5Outlined",
  "WeiboOutlined",
  "TwitterOutlined",
  "WechatOutlined",
  "WhatsAppOutlined",
  "YoutubeOutlined",
  "AlipayCircleOutlined",
  "TaobaoOutlined",
  "DingtalkOutlined",
  "SkypeOutlined",
  "QqOutlined",
  "MediumWorkmarkOutlined",
  "GitlabOutlined",
  "MediumOutlined",
  "LinkedinOutlined",
  "GooglePlusOutlined",
  "DropboxOutlined",
  "FacebookOutlined",
  "CodepenOutlined",
  "CodeSandboxOutlined",
  "AmazonOutlined",
  "GoogleOutlined",
  "CodepenCircleOutlined",
  "AlipayOutlined",
  "AntDesignOutlined",
  "AntCloudOutlined",
  "AliyunOutlined",
  "ZhihuOutlined",
  "SlackOutlined",
  "SlackSquareOutlined",
  "BehanceOutlined",
  "BehanceSquareOutlined",
  "DribbbleOutlined",
  "DribbbleSquareOutlined",
  "InstagramOutlined",
  "YuqueOutlined",
  "AlibabaOutlined",
  "YahooOutlined",
  "RedditOutlined",
  "SketchOutlined"
]

// 网站通用图标-线框风格
export const otherOutlined: string[] = [
  "AccountBookOutlined",
  "AimOutlined",
  "AlertOutlined",
  "ApartmentOutlined",
  "ApiOutlined",
  "AppstoreAddOutlined",
  "AppstoreOutlined",
  "AudioOutlined",
  "AudioMutedOutlined",
  "AuditOutlined",
  "BankOutlined",
  "BarcodeOutlined",
  "BarsOutlined",
  "BellOutlined",
  "BlockOutlined",
  "BookOutlined",
  "BorderOutlined",
  "BorderlessTableOutlined",
  "BranchesOutlined",
  "BugOutlined",
  "BuildOutlined",
  "BulbOutlined",
  "CalculatorOutlined",
  "CalendarOutlined",
  "CameraOutlined",
  "CarOutlined",
  "CarryOutOutlined",
  "CiCircleOutlined",
  "CiOutlined",
  "ClearOutlined",
  "CloudDownloadOutlined",
  "CloudOutlined",
  "CloudServerOutlined",
  "CloudSyncOutlined",
  "CloudUploadOutlined",
  "ClusterOutlined",
  "CodeOutlined",
  "CoffeeOutlined",
  "CommentOutlined",
  "CompassOutlined",
  "CompressOutlined",
  "ConsoleSqlOutlined",
  "ContactsOutlined",
  "ContainerOutlined",
  "ControlOutlined",
  "CopyrightOutlined",
  "CreditCardOutlined",
  "CrownOutlined",
  "CustomerServiceOutlined",
  "DashboardOutlined",
  "DatabaseOutlined",
  "DeleteColumnOutlined",
  "DeleteRowOutlined",
  "DeliveredProcedureOutlined",
  "DeploymentUnitOutlined",
  "DesktopOutlined",
  "DisconnectOutlined",
  "DislikeOutlined",
  "DollarOutlined",
  "DownloadOutlined",
  "EllipsisOutlined",
  "EnvironmentOutlined",
  "EuroCircleOutlined",
  "EuroOutlined",
  "ExceptionOutlined",
  "ExpandAltOutlined",
  "ExpandOutlined",
  "ExperimentOutlined",
  "ExportOutlined",
  "EyeOutlined",
  "EyeInvisibleOutlined",
  "FieldBinaryOutlined",
  "FieldNumberOutlined",
  "FieldStringOutlined",
  "FieldTimeOutlined",
  "FileAddOutlined",
  "FileDoneOutlined",
  "FileExcelOutlined",
  "FileExclamationOutlined",
  "FileOutlined",
  "FileGifOutlined",
  "FileImageOutlined",
  "FileJpgOutlined",
  "FileMarkdownOutlined",
  "FilePdfOutlined",
  "FilePptOutlined",
  "FileProtectOutlined",
  "FileSearchOutlined",
  "FileSyncOutlined",
  "FileTextOutlined",
  "FileUnknownOutlined",
  "FileWordOutlined",
  "FileZipOutlined",
  "FilterOutlined",
  "FireOutlined",
  "FlagOutlined",
  "FolderAddOutlined",
  "FolderOutlined",
  "FolderOpenOutlined",
  "FolderViewOutlined",
  "ForkOutlined",
  "FormatPainterOutlined",
  "FrownOutlined",
  "FunctionOutlined",
  "FundProjectionScreenOutlined",
  "FundViewOutlined",
  "FunnelPlotOutlined",
  "GatewayOutlined",
  "GifOutlined",
  "GiftOutlined",
  "GlobalOutlined",
  "GoldOutlined",
  "GroupOutlined",
  "HddOutlined",
  "HeartOutlined",
  "HistoryOutlined",
  "HolderOutlined",
  "HomeOutlined",
  "HourglassOutlined",
  "IdcardOutlined",
  "ImportOutlined",
  "InboxOutlined",
  "InsertRowAboveOutlined",
  "InsertRowBelowOutlined",
  "InsertRowLeftOutlined",
  "InsertRowRightOutlined",
  "InsuranceOutlined",
  "InteractionOutlined",
  "KeyOutlined",
  "LaptopOutlined",
  "LayoutOutlined",
  "LikeOutlined",
  "LineOutlined",
  "LinkOutlined",
  "Loading3QuartersOutlined",
  "LoadingOutlined",
  "LockOutlined",
  "MacCommandOutlined",
  "MailOutlined",
  "ManOutlined",
  "MedicineBoxOutlined",
  "MehOutlined",
  "MenuOutlined",
  "MergeCellsOutlined",
  "MessageOutlined",
  "MobileOutlined",
  "MoneyCollectOutlined",
  "MonitorOutlined",
  "MoreOutlined",
  "NodeCollapseOutlined",
  "NodeExpandOutlined",
  "NodeIndexOutlined",
  "NotificationOutlined",
  "NumberOutlined",
  "OneToOneOutlined",
  "PaperClipOutlined",
  "PartitionOutlined",
  "PayCircleOutlined",
  "PercentageOutlined",
  "PhoneOutlined",
  "PictureOutlined",
  "PlaySquareOutlined",
  "PoundCircleOutlined",
  "PoundOutlined",
  "PoweroffOutlined",
  "PrinterOutlined",
  "ProfileOutlined",
  "ProjectOutlined",
  "PropertySafetyOutlined",
  "PullRequestOutlined",
  "PushpinOutlined",
  "QrcodeOutlined",
  "ReadOutlined",
  "ReconciliationOutlined",
  "RedEnvelopeOutlined",
  "ReloadOutlined",
  "RestOutlined",
  "RobotOutlined",
  "RocketOutlined",
  "RotateLeftOutlined",
  "RotateRightOutlined",
  "SafetyCertificateOutlined",
  "SafetyOutlined",
  "SaveOutlined",
  "ScanOutlined",
  "ScheduleOutlined",
  "SearchOutlined",
  "SecurityScanOutlined",
  "SelectOutlined",
  "SendOutlined",
  "SettingOutlined",
  "ShakeOutlined",
  "ShareAltOutlined",
  "ShopOutlined",
  "ShoppingCartOutlined",
  "ShoppingOutlined",
  "SisternodeOutlined",
  "SkinOutlined",
  "SmileOutlined",
  "SolutionOutlined",
  "SoundOutlined",
  "SplitCellsOutlined",
  "StarOutlined",
  "SubnodeOutlined",
  "SwitcherOutlined",
  "SyncOutlined",
  "TableOutlined",
  "TabletOutlined",
  "TagOutlined",
  "TagsOutlined",
  "TeamOutlined",
  "ThunderboltOutlined",
  "ToTopOutlined",
  "ToolOutlined",
  "TrademarkCircleOutlined",
  "TrademarkOutlined",
  "TransactionOutlined",
  "TranslationOutlined",
  "TrophyOutlined",
  "UngroupOutlined",
  "UnlockOutlined",
  "UploadOutlined",
  "UsbOutlined",
  "UserAddOutlined",
  "UserDeleteOutlined",
  "UserOutlined",
  "UserSwitchOutlined",
  "UsergroupAddOutlined",
  "UsergroupDeleteOutlined",
  "VerifiedOutlined",
  "VideoCameraAddOutlined",
  "VideoCameraOutlined",
  "WalletOutlined",
  "WifiOutlined",
  "WomanOutlined"
]

// 方向性图标-实底风格
export const directionFilled: string[] = [
  "StepBackwardFilled",
  "StepForwardFilled",
  "FastBackwardFilled",
  "FastForwardFilled",
  "CaretUpFilled",
  "CaretDownFilled",
  "CaretLeftFilled",
  "CaretRightFilled",
  "UpCircleFilled",
  "DownCircleFilled",
  "LeftCircleFilled",
  "RightCircleFilled",
  "ForwardFilled",
  "BackwardFilled",
  "PlayCircleFilled",
  "UpSquareFilled",
  "DownSquareFilled",
  "LeftSquareFilled",
  "RightSquareFilled"
]

// 提示建议性图标-实底风格
export const suggestionFilled: string[] = [
  "QuestionCircleFilled",
  "PlusCircleFilled",
  "PauseCircleFilled",
  "MinusCircleFilled",
  "PlusSquareFilled",
  "MinusSquareFilled",
  "InfoCircleFilled",
  "ExclamationCircleFilled",
  "CloseCircleFilled",
  "CloseSquareFilled",
  "CheckCircleFilled",
  "CheckSquareFilled",
  "ClockCircleFilled",
  "WarningFilled",
  "StopFilled"
]

// 编辑类图标-实底风格
export const editorFilled: string[] = [
  "EditFilled",
  "CopyFilled",
  "DeleteFilled",
  "SnippetsFilled",
  "DiffFilled",
  "HighlightFilled"
]

// 数据类图标-实底风格
export const dataFilled: string[] = [
  "PieChartFilled",
  "BoxPlotFilled",
  "FundFilled",
  "SlidersFilled"
]

// 品牌类图标-实底风格
export const logoFilled: string[] = [
  "AndroidFilled",
  "AppleFilled",
  "WindowsFilled",
  "ChromeFilled",
  "GithubFilled",
  "AliwangwangFilled",
  "WeiboSquareFilled",
  "WeiboCircleFilled",
  "TaobaoCircleFilled",
  "Html5Filled",
  "WechatFilled",
  "YoutubeFilled",
  "AlipayCircleFilled",
  "SkypeFilled",
  "GitlabFilled",
  "LinkedinFilled",
  "FacebookFilled",
  "CodeSandboxCircleFilled",
  "CodepenCircleFilled",
  "SlackSquareFilled",
  "BehanceSquareFilled",
  "DribbbleSquareFilled",
  "InstagramFilled",
  "YuqueFilled",
  "YahooFilled"
]

// 网站通用图标-实底风格
export const otherFilled: string[] = [
  "AccountBookFilled",
  "AlertFilled",
  "AlipaySquareFilled",
  "AmazonCircleFilled",
  "AmazonSquareFilled",
  "ApiFilled",
  "AppstoreFilled",
  "AudioFilled",
  "BankFilled",
  "BehanceCircleFilled",
  "BellFilled",
  "BookFilled",
  "BugFilled",
  "BuildFilled",
  "BulbFilled",
  "CalculatorFilled",
  "CalendarFilled",
  "CameraFilled",
  "CarFilled",
  "CarryOutFilled",
  "CiCircleFilled",
  "CloudFilled",
  "CodeFilled",
  "CodeSandboxSquareFilled",
  "CodepenSquareFilled",
  "CompassFilled",
  "ContactsFilled",
  "ContainerFilled",
  "ControlFilled",
  "CreditCardFilled",
  "CrownFilled",
  "CustomerServiceFilled",
  "DashboardFilled",
  "DatabaseFilled",
  "DingtalkCircleFilled",
  "DingtalkSquareFilled",
  "DislikeFilled",
  "DribbbleCircleFilled",
  "DropboxCircleFilled",
  "DropboxSquareFilled",
  "EnvironmentFilled",
  "EuroCircleFilled",
  "ExperimentFilled",
  "EyeFilled",
  "EyeInvisibleFilled",
  "FileAddFilled",
  "FileExcelFilled",
  "FileExclamationFilled",
  "FileFilled",
  "FileImageFilled",
  "FileMarkdownFilled",
  "FilePdfFilled",
  "FilePptFilled",
  "FileTextFilled",
  "FileUnknownFilled",
  "FileWordFilled",
  "FileZipFilled",
  "FilterFilled",
  "FireFilled",
  "FlagFilled",
  "FolderAddFilled",
  "FolderFilled",
  "FolderOpenFilled",
  "FormatPainterFilled",
  "FrownFilled",
  "FunnelPlotFilled",
  "GiftFilled",
  "GoldFilled",
  "GoldenFilled",
  "GoogleCircleFilled",
  "GooglePlusCircleFilled",
  "GooglePlusSquareFilled",
  "GoogleSquareFilled",
  "HddFilled",
  "HeartFilled",
  "HomeFilled",
  "HourglassFilled",
  "IdcardFilled",
  "IeCircleFilled",
  "IeSquareFilled",
  "InsuranceFilled",
  "InteractionFilled",
  "LayoutFilled",
  "LikeFilled",
  "LockFilled",
  "MacCommandFilled",
  "MailFilled",
  "MedicineBoxFilled",
  "MediumCircleFilled",
  "MediumSquareFilled",
  "MehFilled",
  "MessageFilled",
  "MobileFilled",
  "MoneyCollectFilled",
  "NotificationFilled",
  "PayCircleFilled",
  "PhoneFilled",
  "PictureFilled",
  "PlaySquareFilled",
  "PoundCircleFilled",
  "PrinterFilled",
  "ProfileFilled",
  "ProjectFilled",
  "PropertySafetyFilled",
  "PushpinFilled",
  "QqCircleFilled",
  "QqSquareFilled",
  "ReadFilled",
  "ReconciliationFilled",
  "RedEnvelopeFilled",
  "RedditCircleFilled",
  "RedditSquareFilled",
  "RestFilled",
  "RobotFilled",
  "RocketFilled",
  "SafetyCertificateFilled",
  "SaveFilled",
  "ScheduleFilled",
  "SecurityScanFilled",
  "SettingFilled",
  "ShopFilled",
  "ShoppingFilled",
  "SignalFilled",
  "SketchCircleFilled",
  "SketchSquareFilled",
  "SkinFilled",
  "SlackCircleFilled",
  "SmileFilled",
  "SoundFilled",
  "StarFilled",
  "SwitcherFilled",
  "TabletFilled",
  "TagFilled",
  "TagsFilled",
  "TaobaoSquareFilled",
  "ThunderboltFilled",
  "ToolFilled",
  "TrademarkCircleFilled",
  "TrophyFilled",
  "TwitterCircleFilled",
  "TwitterSquareFilled",
  "UnlockFilled",
  "UsbFilled",
  "VideoCameraFilled",
  "WalletFilled",
  "ZhihuCircleFilled",
  "ZhihuSquareFilled"
]

// 方向性图标-双色风格
export const directionTwoTone: string[] = [
  "UpCircleTwoTone",
  "DownCircleTwoTone",
  "LeftCircleTwoTone",
  "RightCircleTwoTone",
  "PlayCircleTwoTone",
  "UpSquareTwoTone",
  "DownSquareTwoTone",
  "LeftSquareTwoTone",
  "RightSquareTwoTone"
]

// 提示建议性图标-双色风格
export const suggestionTwoTone: string[] = [
  "QuestionCircleTwoTone",
  "PlusCircleTwoTone",
  "PauseCircleTwoTone",
  "MinusCircleTwoTone",
  "PlusSquareTwoTone",
  "MinusSquareTwoTone",
  "InfoCircleTwoTone",
  "ExclamationCircleTwoTone",
  "CloseCircleTwoTone",
  "CloseSquareTwoTone",
  "CheckCircleTwoTone",
  "CheckSquareTwoTone",
  "ClockCircleTwoTone",
  "WarningTwoTone",
  "StopTwoTone"
]

// 编辑类图标-双色风格
export const editorTwoTone: string[] = [
  "EditTwoTone",
  "CopyTwoTone",
  "DeleteTwoTone",
  "SnippetsTwoTone",
  "DiffTwoTone",
  "HighlightTwoTone"
]

// 数据类图标-双色风格
export const dataTwoTone: string[] = [
  "PieChartTwoTone",
  "BoxPlotTwoTone",
  "FundTwoTone",
  "SlidersTwoTone"
]

// 品牌和标识-双色风格
export const logoTwoTone: string[] = ["Html5TwoTone"]

// 网站通用图标-双色风格
export const otherTwoTone: string[] = [
  "AccountBookTwoTone",
  "AlertTwoTone",
  "ApiTwoTone",
  "AppstoreTwoTone",
  "AudioTwoTone",
  "BankTwoTone",
  "BellTwoTone",
  "BookTwoTone",
  "BugTwoTone",
  "BuildTwoTone",
  "BulbTwoTone",
  "CalculatorTwoTone",
  "CalendarTwoTone",
  "CameraTwoTone",
  "CarTwoTone",
  "CarryOutTwoTone",
  "CiCircleTwoTone",
  "CiTwoTone",
  "CloudTwoTone",
  "CodeTwoTone",
  "CompassTwoTone",
  "ContactsTwoTone",
  "ContainerTwoTone",
  "ControlTwoTone",
  "CopyrightTwoTone",
  "CreditCardTwoTone",
  "CrownTwoTone",
  "CustomerServiceTwoTone",
  "DashboardTwoTone",
  "DatabaseTwoTone",
  "DislikeTwoTone",
  "DollarTwoTone",
  "EnvironmentTwoTone",
  "EuroCircleTwoTone",
  "EuroTwoTone",
  "ExperimentTwoTone",
  "EyeTwoTone",
  "EyeInvisibleTwoTone",
  "FileAddTwoTone",
  "FileExcelTwoTone",
  "FileExclamationTwoTone",
  "FileTwoTone",
  "FileImageTwoTone",
  "FileMarkdownTwoTone",
  "FilePdfTwoTone",
  "FilePptTwoTone",
  "FileTextTwoTone",
  "FileUnknownTwoTone",
  "FileWordTwoTone",
  "FileZipTwoTone",
  "FilterTwoTone",
  "FireTwoTone",
  "FlagTwoTone",
  "FolderAddTwoTone",
  "FolderTwoTone",
  "FolderOpenTwoTone",
  "FrownTwoTone",
  "FunnelPlotTwoTone",
  "GiftTwoTone",
  "GoldTwoTone",
  "HddTwoTone",
  "HeartTwoTone",
  "HomeTwoTone",
  "HourglassTwoTone",
  "IdcardTwoTone",
  "InsuranceTwoTone",
  "InteractionTwoTone",
  "LayoutTwoTone",
  "LikeTwoTone",
  "LockTwoTone",
  "MailTwoTone",
  "MedicineBoxTwoTone",
  "MehTwoTone",
  "MessageTwoTone",
  "MobileTwoTone",
  "MoneyCollectTwoTone",
  "NotificationTwoTone",
  "PhoneTwoTone",
  "PictureTwoTone",
  "PlaySquareTwoTone",
  "PoundCircleTwoTone",
  "PrinterTwoTone",
  "ProfileTwoTone",
  "ProjectTwoTone",
  "PropertySafetyTwoTone",
  "PushpinTwoTone",
  "ReconciliationTwoTone",
  "RedEnvelopeTwoTone",
  "RestTwoTone",
  "RocketTwoTone",
  "SafetyCertificateTwoTone",
  "SaveTwoTone",
  "ScheduleTwoTone",
  "SecurityScanTwoTone",
  "SettingTwoTone",
  "ShopTwoTone",
  "ShoppingTwoTone",
  "SkinTwoTone",
  "SmileTwoTone",
  "SoundTwoTone",
  "StarTwoTone",
  "SwitcherTwoTone",
  "TabletTwoTone",
  "TagTwoTone",
  "TagsTwoTone",
  "ThunderboltTwoTone",
  "ToolTwoTone",
  "TrademarkCircleTwoTone",
  "TrophyTwoTone",
  "UnlockTwoTone",
  "UsbTwoTone",
  "VideoCameraTwoTone",
  "WalletTwoTone"
]

// 线框风格全部种类数据
export const Outlined = [
  {'title': '方向性图标', key: 'direction', icons: directionOutlined},
  {'title': '提示建议性图标', key: 'suggestion', icons: suggestionOutlined},
  {'title': '编辑类图标', key: 'editor', icons: editorOutlined},
  {'title': '数据类图标', key: 'data', icons: dataOutlined},
  {'title': '品牌和标识', key: 'logo', icons: logoOutlined},
  {'title': '网站通用图标', key: 'other', icons: otherOutlined},
]

// 实底风格全部种类数据
export const Filled = [
  {'title': '方向性图标', key: 'direction', icons: directionFilled},
  {'title': '提示建议性图标', key: 'suggestion', icons: suggestionFilled},
  {'title': '编辑类图标', key: 'editor', icons: editorFilled},
  {'title': '数据类图标', key: 'data', icons: dataFilled},
  {'title': '品牌和标识', key: 'logo', icons: logoFilled},
  {'title': '网站通用图标', key: 'other', icons: otherFilled},
]

// 双色风格全部种类数据
export const TwoTone = [
  {'title': '方向性图标', key: 'direction', icons: directionTwoTone},
  {'title': '提示建议性图标', key: 'suggestion', icons: suggestionTwoTone},
  {'title': '编辑类图标', key: 'editor', icons: editorTwoTone},
  {'title': '数据类图标', key: 'data', icons: dataTwoTone},
  {'title': '品牌和标识', key: 'logo', icons: logoTwoTone},
  {'title': '网站通用图标', key: 'other', icons: otherTwoTone},
]

export const iconData = [
  {'title': '线框风格', key: 'outlined', icons: Outlined},
  {'title': '实底风格', key: 'filled', icons: Filled},
  {'title': '双色风格', key: 'twoTone', icons: TwoTone}
]

