export const CheckboxGroup = {
  'zh-CN': {
    title: '复选框组',
    settings: {
      'x-component-props': {
          options: '可选项',
      },
    }
  },
  'en-US': {
    title: 'Checkbox',
  },
  'ko-KR': {
    title: '체크박스',
  },
}
