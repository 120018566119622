export const Card = {
  'zh-CN': {
    title: '卡片',
    settings: {
      'x-component-props': {
        type: {title: '类型', dataSource: [
          { label: '内置', value: 'inner' },
          { label: '默认', value: '' },
        ]},
        title: '标题',
        extra: '右侧扩展',
        size: {title: '尺寸', dataSource: [
          { label: '默认', value: 'default' },
          { label: '小', value: 'small' },
        ]},
        bodyStyle: '内容样式'
      },
    },
  },
  'en-US': {
    title: 'Card',
    settings: {
      'x-component-props': {
        type: 'Type',
        title: 'Title',
        extra: 'Extra',
        cardTypes: [
          { label: 'Inner', value: 'inner' },
          { label: 'Default', value: '' },
        ],
      },
    },
  },
  'ko-KR': {
    title: '카드',
    settings: {
      'x-component-props': {
        type: '타입',
        title: '제목',
        extra: '추가 항목',
        cardTypes: [
          { label: '안쪽', value: 'inner' },
          { label: '기본', value: '' },
        ],
      },
    },
  },
}
