import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Alert as AntdAlert } from 'antd'
import type { AlertProps } from 'antd/es/alert'
import React from 'react'

export interface FormilyAlertProps extends AlertProps {}
const FormilyAlert: ReactFC<FormilyAlertProps> = connect(
  AntdAlert,
)

export default FormilyAlert
