export const Alert = {
  'zh-CN': {
    title: '警告提示',
    settings: {
      'x-component-props': {
        closable: '是否可关闭',
        type: '类型',
        message: '提示内容',
        description: '辅助性文字介绍',
        showIcon: '显示图标',
        banner: 'banner样式',
        closeText: '关闭按钮自定义内容',
        icon: '自定义图标',
        action: '操作按钮',
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: '颜色'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}
