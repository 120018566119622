import { ISchema } from '@formily/react'
import { ImageInput } from '../../../Formily/src/setters'

export const Avatar: ISchema = {
  type: 'object',
  properties: {
    src: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': ImageInput,
      "x-validator": 'url',
      "x-component-props": {
        placeholder: '上传或输入图片地址'
      }
    },
    fit: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [
        {
          label: '填充',
          value: 'contain'
        },
        {
          label: '覆盖',
          value: 'cover'
        },
        {
          label: '撑满',
          value: 'fill'
        },
        {
          label: '不改变',
          value: 'none'
        },
        {
          label: 'scale-down',
          value: 'scale-down'
        }
      ],
      'x-component-props': {
        defaultValue: 'cover'
      }
    }
  },
}
