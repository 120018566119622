export const ProTable = {
  'zh-CN': {
    title: '展示表格',
    settings: {
      'x-component-props': {
        tableProps: '配置表格',
        showPagination: '是否显示分页',
        size: {
          title: '表格大小',
          dataSource: [
            {
              label: '默认',
              value: 'default',
            },
            {
              label: '中等',
              value: 'middle',
            },
            {
              label: '紧凑',
              value: 'small',
            },
          ],
        },
        pageSize: '每页条数',
        border: '是否显示边框',
        scrollY: '表格滚动高度',
        showSelection: '可选择',
        selectionType: '选择类型'
      },
    },
  },
  'en-US': {
    title: '展示表格',
    settings: {
      'x-component-props': {
        // 略
      },
    },
  },
  'ko-KR': {
    title: '展示表格',
    settings: {
      'x-component-props': {
        // 略
      },
    },
  },
}
