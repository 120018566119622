import { connect, ReactFC } from '@formily/react'
import { Progress } from 'antd'
import type { ProgressProps } from 'antd/es/progress'
export interface FormilyProgressProps extends ProgressProps {}

const AntdProgress: ReactFC<FormilyProgressProps> = connect(
  Progress,
)

export default AntdProgress
