export const Ellipsis = {
  'zh-CN': {
    title: '文本省略',
    settings: {
      'x-component-props': {
        collapseText: '收起操作的文案',
        content: '文本内容',
        expandText: '展开操作的文案',
        rows: '展示几行',
        defaultExpanded: '是否默认展开',
      },
    },
  },
}
