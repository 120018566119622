import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyDivider, { FormilyDividerProps } from "./Divider"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import "./styles.less";

export const Divider: DnFC<FormilyDividerProps> = ({
  ...props
}) => {
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-divider"
      style={ props.type == 'vertical' ? { display: 'inline-block' } : {}}
    >
      <FormilyDivider {...props} ></FormilyDivider>
    </div>
  );
};

Divider.Behavior = createBehavior({
  name: "Divider",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Divider",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Divider),
  },
  designerLocales: AllLocales.Divider,
});

Divider.Resource = createResource({
  icon: "DividerSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Divider",
      },
    },
  ],
});