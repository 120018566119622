import React, { useMemo } from 'react'
import { createBehavior, createResource } from '@designable/core'
import { createForm } from '@formily/core'
import { observer } from '@formily/react'
import { Form as FormilyForm } from '@formily/antd'
import { usePrefix, DnFC } from '@designable/react'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import { ConfigProvider } from 'antd'
import './styles.less'
import { useEffect } from 'react'

interface IFormProps  {
  theme?: any
}

export const Form: DnFC<React.ComponentProps<typeof FormilyForm> & IFormProps> = observer(
  (props) => {
    // console.log(props)
    const theme = props.theme || {}
    const prefix = usePrefix('designable-form')
    useEffect(() => {
      ConfigProvider.config({
        prefixCls: 'custom',
        theme: {
          ...theme,
        },
      });
    }, [theme.primaryColor, theme.errorColor, theme.warningColor, theme.successColor, theme.textColor])
    const form = useMemo(
      () =>
        createForm({
          designable: true,
        }),
      []
    )
    return (
      <ConfigProvider prefixCls='custom'>
      
        <FormilyForm
          {...props}
          style={{ ...props.style }}
          className={prefix}
          form={form}
        >
          {props.children}
        </FormilyForm>
      </ConfigProvider>
    )
  }
)

Form.Behavior = createBehavior({
  name: 'Form',
  selector: (node) => node.componentName === 'Form',
  designerProps(node) {
    return {
      draggable: !node.isRoot,
      cloneable: !node.isRoot,
      deletable: !node.isRoot,
      droppable: true,
      propsSchema: {
        type: 'object',
        properties: {
          'theme-group': {
              title: '配置主题',
              type: 'void',
              'x-component': 'CollapseItem',
              properties: {
                theme: {
                  type: 'object',
                  properties: {
                    primaryColor: {
                      title: '主题色',
                      type: 'string',
                      'x-decorator': 'FormItem',
                      'x-component': 'ColorInput',
                      'x-component-props': {
                        defaultValue: '#1890ff',
                      },
                    },
                    // errorColor: {
                    //   title: '错误提示色',
                    //   type: 'string',
                    //   'x-decorator': 'FormItem',
                    //   'x-component': 'ColorInput',
                    //   'x-component-props': {
                    //     defaultValue: '#ff4d4f',
                    //   },
                    // },
                    // warningColor: {
                    //   title: '警告提示色',
                    //   type: 'string',
                    //   'x-decorator': 'FormItem',
                    //   'x-component': 'ColorInput',
                    //   'x-component-props': {
                    //     defaultValue: '#faad14',
                    //   },
                    // },
                    // successColor: {
                    //   title: '成功提示色',
                    //   type: 'string',
                    //   'x-decorator': 'FormItem',
                    //   'x-component': 'ColorInput',
                    //   'x-component-props': {
                    //     defaultValue: '#52c41a',
                    //   },
                    // },
                    // textColor: {
                    //   title: '主文本色',
                    //   type: 'string',
                    //   'x-decorator': 'FormItem',
                    //   'x-component': 'ColorInput',
                    //   'x-component-props': {
                    //     defaultValue: '#1890ff',
                    //   },
                    // },
                  }
                },
              }
          },
          'style-group': {
              title: '页面样式',
              type: 'void',
              'x-component': 'CollapseItem',
              properties: {
                style: AllSchemas.CSSStyle,
              }
          },
            
          // ...(AllSchemas.FormLayout.properties as any),
          
        },
      },
      defaultProps: {
        labelCol: 6,
        wrapperCol: 12,
      },
    }
  },
  designerLocales: AllLocales.Form,
})

Form.Resource = createResource({
  title: { 'zh-CN': '表单', 'en-US': 'Form' },
  icon: 'FormLayoutSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'object',
        'x-component': 'Form',
      },
    },
  ],
})
