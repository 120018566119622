import { ISchema } from '@formily/react'
import { ClickEventSetter, ClickCustomEventSetter, IconSelect } from '../setters'

export const Button: ISchema = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
    },
    iconName: {
      title: '图标',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': IconSelect,
    },
    'clickEventProps': {
      title: '事件',
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickEventSetter,
      "x-component-props": {
        senceType: 'modal'
      }
    },
    'clickCustomEventProps': {
      title: '能力',
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickCustomEventSetter,
    },
    color: {
      type: 'string',
      enum: [ 'default', 'primary', 'success', 'warning', 'danger'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      "x-component-props": {
        defaultValue: 'default'
      }
    },
    size: {
      type: 'string',
      enum: ['large', 'middle', 'small', 'mini'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      "x-component-props": {
        defaultValue: 'middle'
      }
    },
    shape: {
      title: '按钮形状',
      type: 'string',
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '圆形',
          value: 'rounded',
        },
        {
          label: '长方形',
          value: 'rectangular',
        },
      ],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        allowClear: true,
        defaultValue: 'default'
      },
    },
    block: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    loading: {
      title: '载入状态',
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    // loadingIconName: {
    //   title: '载入状态Icon',
    //   type: 'string',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'Input',
    //   "x-reactions": {
    //     dependencies: ['.loading'],
    //     fulfill: {
    //       state: {
    //         visible: '{{$deps[0]}}'
    //       }
    //     }
    //   }
    // },
    loadingText: {
      title: '载入状态额外文本',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      "x-reactions": {
        dependencies: ['.loading'],
        fulfill: {
          state: {
            visible: '{{$deps[0]}}'
          }
        }
      }
    },
    disabled:  {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    fill: {
      title: '填充模式',
      type: 'string',
      enum: [
        {
          label: '全填充',
          value: 'solid',
        },
        {
          label: '线框',
          value: 'outline',
        },
        {
          label: '无',
          value: 'none',
        },
      ],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        defaultValue: 'solid'
      },
    }
  },
}

