import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'
import {ClickCustomEventSetter} from '../../../FormilyMobile/src/setters'

export const Button: ISchema = {
  type: 'object',
  properties: {
    text: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
    },
    type: {
      type: 'string',
      enum: ['primary', 'default', 'dashed', 'text', 'link'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
    },
    iconName: {
      title: '图标',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': IconSelect,
    },
    'clickEventProps': {
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickEventSetter,
      "x-component-props": {
        senceType: 'button',
        showEnable: true
      }
    },
    'clickCustomEventProps': {
      title: '能力',
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickCustomEventSetter,
    },
    size: {
      type: 'string',
      enum: ['large', 'middle', 'small'],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
    },
    shape: {
      title: '按钮形状',
      type: 'string',
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '圆形',
          value: 'circle',
        },
        {
          label: '长方形',
          value: 'round',
        },
      ],
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      'x-component-props': {
        allowClear: true,
        defaultValue: 'default'
      },
    },
    block: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    loading: {
      title: '载入状态',
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    danger: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    disabled:  {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    ghost:  {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    hideModal: {
      title: '关闭弹窗',
      "x-decorator": 'FormItem',
      "x-component": 'Switch',
      "x-decorator-props": {
        tooltip: '只在弹窗页面生效'
      }
    }
    // searchArr: {
    //   type: 'string',
    //   'x-decorator': 'FormItem',
    //   'x-component': PathSel,
    //   "x-reactions": {
    //     "dependencies": [".eventType"], //依赖路径写法默认是取value，如果依赖的是字段的其他属性，可以使用 source#modified，用#分割取详细属性
    //     "fulfill": {
    //       "state": {
    //         "hidden": "{{ $deps[0] !== 'buttonSetterSearch'}}" //任意层次属性都支持表达式
    //       }
    //     }
    //   },
    //   "x-component-props": {
    //     multiple: true
    //   },
    //   description: '多个字段用,分割'
    // },
    // mapParmsToProps: {
    //   type: 'string',
    //   "x-component": 'Input.TextArea',
    //   "x-decorator": 'FormItem',
    //   "x-component-props": {
    //     placeholder: '请输入'
    //   }
    // }
  },
}

