import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Divider as AntdDivider } from 'antd'
import type { DividerProps } from 'antd/es/divider'
import React from 'react'

export interface FormilyDividerProps extends DividerProps {}
const FormilyDivider: ReactFC<FormilyDividerProps> = connect(
  AntdDivider,
)

export default FormilyDivider
