import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'

export const Divider: ISchema = {
  type: "object",
  properties: {
    type: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-component-props": {
        defaultValue: "horizontal",
        optionType: "button",
      },
    },
    children: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        placeholder: "请输入",
        allowClear: true,
      },
      "x-reactions": {
        dependencies: [".type"],
        fulfill: {
          state: {
            hidden: '{{$deps[0] == "vertical"}}',
          },
        },
      },
    },
    orientation: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-component-props": {
        defaultValue: "center",
        optionType: "button",
      },
      "x-reactions": {
        dependencies: [".type"],
        fulfill: {
          state: {
            hidden: '{{$deps[0] == "vertical"}}',
          },
        },
      },
    },
    orientationMargin: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        placeholder: "请输入间距",
        include: ['px', '%'],
      },
      "x-reactions": {
        dependencies: [".orientation"],
        fulfill: {
          state: {
            visible: '{{$deps[0] == "left" || $deps[0] == "right"}}',
          },
        },
      },
    },
    dashed: {
      title: "是否虚线",
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
  },
};
