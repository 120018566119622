import {
  observer,
  ReactFC,
  RecursionField,
  useField,
  useFieldSchema,
  Schema, SchemaKey
} from '@formily/react'
import { markRaw, model } from '@formily/reactive'
import { Badge, Tabs, TabsProps } from 'antd-mobile'
import cls from 'classnames'
import React, { Fragment, useMemo } from 'react'
import { usePrefixCls } from '../../common/utils'
export interface IFormTab {
  activeKey?: string
  setActiveKey(key: string): void
}

export interface IFormTabProps extends TabsProps {
  formTab?: IFormTab
}

export interface IFormTabProps {
  key: string | number
}

interface IFeedbackBadgeProps {
  name: SchemaKey
  tab: React.ReactNode
}

type ComposedFormTab = React.FC<React.PropsWithChildren<IFormTabProps>> & {
  Tab: React.FC<React.PropsWithChildren<IFormTabProps>>
  createFormTab: (defaultActiveKey?: string) => IFormTab
}

const useTabs = () => {
  const tabsField = useField()
  const schema = useFieldSchema()
  const tabs: { name: SchemaKey; props: any; schema: Schema }[] = []
  schema.mapProperties((schema, name) => {
    const field = tabsField.query(tabsField.address.concat(name)).take()
    if (field?.display === 'none' || field?.display === 'hidden') return
    if (schema['x-component']?.indexOf('Tab') > -1) {
      const key =
        field?.componentProps?.key || schema?.['x-component-props']?.key || name
      tabs.push({
        name,
        props: {
          ...schema?.['x-component-props'],
          ...field?.componentProps,
          key,
        },
        schema,
      })
    }
  })
  return tabs
}

const createFormTab = (defaultActiveKey?: string) => {
  const formTab = model({
    activeKey: defaultActiveKey,
    setActiveKey(key: string) {
      formTab.activeKey = key
    },
  })
  return markRaw(formTab)
}

export const FormTab: ComposedFormTab = observer(
  ({ formTab, ...props }: IFormTabProps) => {
    const tabs = useTabs()
    const _formTab = useMemo(() => {
      return formTab ? formTab : createFormTab()
    }, [])
    const prefixCls = usePrefixCls('formily-tab', props)
    const activeKey = props.activeKey || _formTab?.activeKey

    return (
      <Tabs
        {...props}
        className={cls(prefixCls, props.className)}
        activeKey={activeKey}
        onChange={(key) => {
          props.onChange?.(key)
          _formTab?.setActiveKey?.(key)
        }}
      >
        {tabs.map(({ props, schema, name }, key) => {
          return <Tabs.Tab key={props.key || key} title={props.tab || '未命名'}>
             <RecursionField schema={schema} name={name} />
          </Tabs.Tab>
        })}
      </Tabs>
    )
  }
) as unknown as ComposedFormTab

const Tab: React.FC<React.PropsWithChildren<IFormTabProps>> = ({
  children,
}) => {
  return <Fragment>{children}</Fragment>
}

FormTab.Tab = Tab
FormTab.createFormTab = createFormTab

export default FormTab
