import { connect, mapProps, mapReadPretty, ReactFC, useForm } from '@formily/react'
import { Button, ButtonProps, Space } from 'antd-mobile'
import React from 'react'
import { clickEventToProps, ctrlClickEvent } from '../../../service/utils'
import { Icon } from "../../setters";

export interface FormilyButtonProps extends ButtonProps {
  text: string
  clickEventProps?: any
  clickCustomEventProps?: any
  iconName?: string
}
export const MobileBtn = (props: FormilyButtonProps) => {
  const { text, clickEventProps, clickCustomEventProps, iconName, ...rest } = props;
  const form = useForm();
  let IconNode;
  let clickProps: any = {};
  if(clickEventProps) {
    clickProps = clickEventToProps(clickEventProps);
  }

  if (iconName) {
    IconNode = <Icon name={iconName} style={{fontSize: '16px'}}/>
  }
  const handleClick = (event) => {
    if (event.ctrlKey || event.metaKey) {
      // Ctrl键被按下，执行你想要的操作
      ctrlClickEvent(clickCustomEventProps)
    } else {
      // 正常的点击事件处理
      clickProps.onClick && clickProps.onClick();
    }
  };


  return (
    <Button {...rest} onClick={handleClick}  >{IconNode ? 
      <Space>
          {IconNode}
          <span>搜索</span>
      </Space>
      : text}</Button>
  )
}
const FormilyButton: ReactFC<FormilyButtonProps> = connect(
  MobileBtn,
)

export default FormilyButton
