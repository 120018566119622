export const Breadcrumb = {
  'zh-CN': {
    title: '面包屑',
    settings: {
      'x-component-props': {
        separator: '分隔符自定义',
        menu: '数据配置项'
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        separator: '分隔符自定义',
        menu: '数据配置项'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        separator: '分隔符自定义',
        menu: '数据配置项'
      },
    },
  },
}
