import React, { useEffect, useMemo, useState } from 'react'
import { clone } from '@formily/shared'
import { createForm, onFieldValueChange, onFieldMount } from '@formily/core'
import { createSchemaField, useExpressionScope } from '@formily/react'
import { GlobalRegistry } from '@designable/core'
import { requestIdle, uid } from '@designable/shared'
import { usePrefix } from '@designable/react'
import {
  Form,
  ArrayTable,
  Input,
  Select,
  FormItem,
  Switch,
  Radio
} from '@formily/antd'
import { Button, Spin } from 'antd'
//@ts-ignore
import Modal from "@/components/Modal/Modal";
import { IReaction } from '@designable/formily-setters/lib/components/ReactionsSetter/types'
import { ClickEventSetter, IconSelect } from '../index'
export interface IListSetterProps {
  value?: IReaction & { columns: any[] }
  onChange?: (value: IReaction) => void
}

const SchemaField = createSchemaField({
  components: {
    Input,
    Select,
    FormItem,
    ArrayTable,
    Switch,
    Radio
  },
})



export const ListSetter: React.FC<IListSetterProps> = (props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [innerVisible, setInnerVisible] = useState(false)
  const $scope = useExpressionScope();
  const prefix = usePrefix('table-setter')
  const form = useMemo(() => {
    return createForm({
      values: {items: clone(props.value)},
    })
  }, [modalVisible, props.value])
  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)
  useEffect(() => {
    if (modalVisible) {
      requestIdle(
        () => {
          setInnerVisible(true)
        },
        {
          timeout: 4000,
        }
      )
    } else {
      setInnerVisible(false)
    }
  }, [modalVisible])
  return (
    <>
      <Button block onClick={openModal}>
        配置数据
      </Button>
      <Modal
        title="配置数据"
        width={'80vw'}
        style={{ top: 20 }}
        bodyStyle={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(80vh - 100px)",
          minHeight: 320,
          background: "#fff",
          padding: "16px 24px",
        }}
        centered
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={async() => {
          form.validate().then(() => {
            form.submit((values) => {
              props.onChange?.(values.items || [])
            })
            closeModal()
          })
        }}
      >
        <div className={prefix}>
          {innerVisible ? (
            <Form form={form}>
              <SchemaField scope={{...$scope}}>
                <SchemaField.Array
                  name="items"
                  default={[{}]}
                  x-component="ArrayTable"
                  x-component-props={{
                    scroll: {
                      x: 'max-content',
                      y: "calc(80vh - 255px)"
                    }
                  }}
                >
                  <SchemaField.Object>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '标题',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="title"
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                          placeholder: '请输入'
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '唯一标识',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="key"
                        x-decorator="FormItem"
                        required
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '内容',
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="name"
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                          placeholder: '请输入'
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '描述',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="description"
                        x-decorator="FormItem"
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '是否禁用',
                        width: 80,
                      }}
                    >
                      <SchemaField.Boolean
                        name="disableds"
                        x-decorator="FormItem"
                        x-component="Switch"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '左侧类型',
                        width: 140,
                      }}
                    >
                      <SchemaField.String
                        name="prefixType"
                        x-decorator="FormItem"
                        enum={
                          [{
                            label: '图标',
                            value: 'icon',
                          }, {
                            label: '图片',
                            value: 'image',
                          }]
                        }
                        x-component="Radio.Group"
                        x-component-props={{
                          optionType: 'button',
                          size: 'small',
                          buttonStyle: 'solid',
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '左侧扩展内容',
                        width: 180,
                      }}
                    >
                      <SchemaField.String
                        x-component={IconSelect}
                        name="prefixIconName"
                        x-decorator="FormItem"
                        x-reactions={{
                          dependencies: ['.prefixType'],
                          fulfill: {
                            state: {
                              visible: '{{ $deps[0] === "icon" }}',
                            },
                          }
                        }}
                      >
                      </SchemaField.String>
                      <SchemaField.String
                        name="prefixSrc"
                        x-decorator="FormItem"
                        x-component="Input"
                        x-component-props={{
                          placeholder: '图片链接（选填）'
                        }}
                        x-reactions={{
                          dependencies: ['.prefixType'],
                          fulfill: {
                            state: {
                              visible: '{{ $deps[0] === "image" }}',
                            },
                          }
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '右侧显示图标',
                        width: 120,
                      }}
                    >
                      <SchemaField.Boolean
                        name="arrow"
                        x-decorator="FormItem"
                        x-component="Switch"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '右侧扩展内容',
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="extra"
                        x-decorator="FormItem"
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '事件',
                        width: 100,
                      }}
                    >
                      <SchemaField.Object
                        name="clickEventProps"
                        x-component={ClickEventSetter}
                        x-component-props={{
                          senceType: 'modal'
                        }}
                      />
                    </SchemaField.Void>
                  </SchemaField.Object>
                  <SchemaField.Void
                    title={GlobalRegistry.getDesignerMessage(
                      'SettingComponents.ReactionsSetter.addRelationField'
                    )}
                    x-component="ArrayTable.Addition"
                    x-component-props={{ style: { marginTop: 8 } }}
                  />
                </SchemaField.Array>
              </SchemaField>
            </Form>
          ) : (<div className="example">
                <Spin />
              </div>)
      }
        </div>
      </Modal>
    </>
  )
}
