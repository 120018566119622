import { ISchema } from '@formily/react'
import { GridLayoutColumnsSetter } from '../setters'

export const Grid: ISchema & { GridColumn?: ISchema } = {
  type: 'object',
  properties: {
    columns: {
      type:'string',
      'x-component': GridLayoutColumnsSetter,
      'x-component-props': {
          min: ['px'],
      },
    },
    rowMargin: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'SizeInput',
      'x-component-props': {
        include: ['px','rem','%'],
        placeholder: '请输入数字'
      },
    },
    columnMargin: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'SizeInput',
      'x-component-props': {
          include: ['px','rem','%'],
          placeholder: '请输入数字'
      },
    },
  },
}

Grid.GridColumn = {
  type: 'object',
  properties: {
    // gridSpan: {
    //   type: 'number',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'NumberPicker',
    //   'x-component-props': {
    //     defaultValue: 1,
    //   },
    // },
  },
}
