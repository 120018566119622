export const List = {
  'zh-CN': {
    title: '列表',
    settings: {
      'x-component-props': {
        items: '数据',
        header: '标题内容',
        mode: '模式',
      },
    },
  },
}
