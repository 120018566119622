export const Steps = {
  'zh-CN': {
    title: '步骤条',
    settings: {
      'x-component-props': {
        steps: '配置数据',
        current: '指定当前步骤',
        direction: '步骤条方向',
        type: '类型',
        labelPlacement: '标签放置位置',
        size: '大小',
        status: '状态',
        progressDot: '自定义进度点',
      },
    },
  },
}

export const StepItem = {
  'zh-CN': {
    title: '步骤',
    settings: {
      'x-component-props': {
        title: '标题',
        subTitle: '子标题',
        description: '描述',
        icon: '图标',
        // status: '状态',
        disabled: '禁用',
      },
    },
  },
}
