import React from 'react'
import { Card as AntdCard } from 'antd'

import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import './styles.less'

export const Card: DnFC<React.ComponentProps<typeof AntdCard>> = (props) => {
  return (
    <AntdCard
      className='dn-formily-card'
      {...props}
      title={
        props.title ? 
        <span>
          {props.title}
        </span>
        : null
      }
    >
      {props.children}
    </AntdCard>
  )
}

Card.Behavior = createBehavior({
  name: 'Card',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Card',
  designerProps: {
    droppable: true,
    propsSchema: createBaseSchema(AllSchemas.Card, {
      type: 'void',
      title: '内容样式',
      'x-component': 'CollapseItem',
      'x-component-props': { defaultExpand: true },
      properties: {
        'x-component-props.style': AllSchemas.CSSStyle,
      },
    })
  },
  designerLocales: AllLocales.Card,
})

Card.Resource = createResource({
  icon: 'CardSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Card',
        'x-component-props': {
          title: 'Title',
        },
      },
    },
  ],
})
