export const Divider = {
  'zh-CN': {
    title: '分割线',
    settings: {
      'x-component-props': {
        children: '文字',
        orientation: {
          title: '文字位置',
          dataSource: [
            { label: '左', value: 'left' },
            { label: '中', value: 'center' },
            { label: '右', value: 'right' },
          ],
        },
        orientationMargin: '文字偏移距离',
        type: {
          title: '类型',
          dataSource: [
            { label: '水平', value: 'horizontal' },
            { label: '垂直', value: 'vertical' },
          ],
        },
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: '颜色'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}
