export const Avatar = {
  'zh-CN': {
    title: '头像',
    settings: {
      'x-component-props': {
        src: '头像的图片地址',
        fit: "图片填充模式",
      },
    },
  },
}
