import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'
import { DataSourceSetter } from '@designable/formily-setters'

export const Tree: ISchema = {
  type: 'object',
  properties: {
    treeData: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': DataSourceSetter,
    },
    checkable: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    defaultExpandAll: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    disabled: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    iconName: {
      title: '图标',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': IconSelect,
    },
  },
}
