export const NavBar = {
  'zh-CN': {
    title: '导航栏',
    settings: {
      'x-component-props': {
        children: '标题',
        title: '标题',
      },
    },
  },
}
