import {
    transformToSchema,
    transformToTreeNode,
  } from '@designable/formily-transformer'
  import { message } from 'antd'
  // @ts-ignore
  import useHTTP from "@/hooks/useHTTP.mjs";
  // @ts-ignore
  import runAsync from "@/script/runAsync.mjs";
  import { useMemo } from 'react';
  
  export const useSchemaService =  (uvMode = 2) => {
    const http = useHTTP();
    return useMemo(() => {
      const saveLocalSchema = (designer) => {
        localStorage.setItem(
          'formily-schema',
          JSON.stringify(transformToSchema(designer.getCurrentTree()))
        )
        message.success("保存成功")
      }
      const loadInitialSchema = (designer) => {
        try {
          designer.setCurrentTree(
            transformToTreeNode(JSON.parse(localStorage.getItem('formily-schema')))
          )
        } catch {}
      }
      const saveSchema = async (designer, id, uvType = "PC") => {
        try {
          let saveUrl = '/modifyUmUvMap';
          const parms = {
            ...designer.prjData,
            pkid: id, 
            uvMap: JSON.stringify({uvType,...transformToSchema(designer.getCurrentTree())})
          }
          const data = await runAsync(
            async () => {
                return await http.post(saveUrl, parms);
            }, {
            action: '保存',
            error: { content: (err) => `保存失败: ${err.message}` },
            loading: { show: true },
            success: { show: true },
          });
        } catch (error) {
          console.log('保存失败')
        }
      }
      const loadSchema = async (designer, id) => {
        try {
            const url = '/oneUmUv'
            const data = await runAsync(
              async () => {
                  return await http.get(url, {pkid: id});
              }, {
                  error: {content: (err) => `查询失败: ${err.message}`},
                  loading: {show: true},
                  success: {show: false},
              });
            // if (uvMode == 1) {
            //   data.uvMap = data.content;
            //   data.uvName = data.name
            //   delete data.content;
            // }
            if (!!designer && data['uvMap']) {
              designer.setCurrentTree(
                transformToTreeNode(JSON.parse(data['uvMap']))
              )
            }
            return data;
        } catch (err) {
            console.log(err);
        }
      }
      const readList = async (params) => {
        try {
          const data = await runAsync(
            async () => {
                return await http.get("/umuis/ueAndAlgs", params);
            }, {
            error: { content: (err) => `查询失败: ${err.message}` },
            loading: { show: false },
            success: { show: false },
          });
        } catch(err) {
          console.log(err);
        }
      }
      return {
        saveLocalSchema,
        loadInitialSchema,
        saveSchema,
        loadSchema,
        readList
      }
    })
  
  }  