import React, { useEffect, useMemo, useState } from 'react'
import { createForm, createEffectHook } from '@formily/core'
import { createSchemaField, FormProvider } from '@formily/react'
import {
  FormItem,
  Input,
  Selector,
  FormLayout,
  Stepper,
  DatePicker,
  Checkbox,
} from '@formily/antd-mobile';
import { Space } from '@formily/antd';
// import { ProTable } from '../src/components/ProTable'
import { CascadePicker, Slider, LayoutGrid as Grid, CheckList } from '../src'
import { Radio, FormCollapse, MobileTag as Tag, MobileBtn as Button, Icon, TabBar, SideBar, NavBar, FormTab, Steps, Swiper, Divider, Ellipsis, ProgressBar, ProgressCircle, List, Affix, Box, RowGrid, Switch } from '../src/components/preivew'
import { Card, Image, SearchBar, ImageUploader, Avatar, Form, TextArea, Toast, Rate as AntdRate } from 'antd-mobile'

import { TreeNode } from '@designable/core'
import { transformToSchema } from '@designable/formily-transformer'
// @ts-ignore
import openWindow from '@/script/openWindow.mjs';

const Rate = (props) => {
  const style = props.style || {};
  return <AntdRate {...props} style={{ position: 'relative', top: '-4px', ...style }} />
}

const Text: React.FC<{
  value?: string
  content?: string
  mode?: 'normal' | 'h1' | 'h2' | 'h3' | 'p'
}> = ({ value, mode, content, ...props }) => {
  const tagName = mode === 'normal' || !mode ? 'div' : mode
  return React.createElement(tagName, props, value || content)
}

const SchemaField = createSchemaField({
  components: {
    Avatar,
    FormItem,
    FormLayout,
    Switch,
    TextArea,
    Input,
    Selector,
    CascadePicker,
    Stepper,
    DatePicker,
    Checkbox,
    CheckList,
    Slider,
    Text,
    Radio,
    Image,
    Card,
    FormCollapse,
    Rate,
    SearchBar,
    Tag,
    Icon,
    Button,
    NavBar,
    TabBar,
    SideBar,
    Space,
    Grid,
    RowGrid,
    FormTab,
    Steps,
    ImageUploader: (props) => (<ImageUploader {...props} upload={async(file) => {
      Toast.show('上传成功')
      return {
        url: URL.createObjectURL(file),
      } 
    }} />),
    Swiper,
    Divider,
    Ellipsis,
    List,
    ProgressBar,
    ProgressCircle,
    Affix,
    Box
  },
  scope: []
})

export interface IPreviewWidgetProps {
  tree: TreeNode
  handleClick: () => void
  initParms: any
}

export const PreviewWidget: React.FC<IPreviewWidgetProps> = (props) => {
  const form = useMemo(() => createForm({
    initialValues: props.initParms || {}
  }), [])
  const { form: formProps={}, schema } = transformToSchema(props.tree)
  const { style={} } = formProps;
  return (
    <div style={{...style}}>
      <FormProvider {...formProps} form={form}>
        <SchemaField schema={schema} />
      </FormProvider>
    </div>
  )
}
