import React from "react";
import FormilyBreadcrumb, { FormilyBreadcrumbProps } from "./Breadcrumb"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";

export const Breadcrumb: DnFC<FormilyBreadcrumbProps> = FormilyBreadcrumb

Breadcrumb.Behavior = createBehavior({
  name: "Breadcrumb",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Breadcrumb",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Breadcrumb),
  },
  designerLocales: AllLocales.Breadcrumb,
});

Breadcrumb.Resource = createResource({
  icon: "BreadcrumbSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Breadcrumb",
        "x-component-props": {
          style: {
            padding: '8px 12px 8px 12px'
          },
          menu: [
            {
              label: '',
              icon: 'HomeOutlined',
            },
            {
              label: '列表页',
              icon: '',
            }
          ]
        },
      },
    },
  ],
});