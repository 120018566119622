import React from "react";
import { useField, useForm } from "@formily/react";
import AntdProgress, { FormilyProgressProps } from "./Progress"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";


export const Progress: DnFC<FormilyProgressProps> = AntdProgress;

Progress.Behavior = createBehavior({
  name: "Progress",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Progress",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Progress),
  },
  designerLocales: AllLocales.Progress,
});

Progress.Resource = createResource({
  icon: "ProgressSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Progress",
        "x-component-props": {
          percent: 50
        },
      },
    },
  ],
});