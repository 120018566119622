import { ISchema } from '@formily/react'

export const Progress: ISchema = {
  type: "object",
  properties: {
    percent: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
    },
    showInfo: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultChecked: true
      }
    },
    status: {
      type: "string",
      enum: ["success", "exception", "active", "normal"],
      "x-decorator": "FormItem",
      "x-component": "Select",
      "x-component-props": {
        defaultValue: "normal",
      },
    },
    type: {
      type: "string",
      enum: ["line", "circle", "dashboard"],
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-component-props": {
        optionType: 'button'
      }
    },
    strokeWidth: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-reactions": {
        dependencies: ['.type'],
        fulfill: {
          state: {
            componentProps: {
              defaultValue: "{{$deps[0] == 'line' ? 10 : 6}}"
            }
          }
        }
      }
    },
    width: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 132
      },
      "x-reactions": {
        dependencies: ['.type'],
        fulfill: {
          state: {
            hidden: "{{$deps[0] != 'line'}}",
          }
        }
      }
    },
    gapDegree: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 75,
        min: 0,
        max: 295,
        description: '仪表盘进度条缺口角度，可取值 0 ~ 295'
      },
      "x-reactions": {
        dependencies: ['.type'],
        fulfill: {
          state: {
            visible: "{{$deps[0] == 'dashboard'}}",
          }
        }
      }
    },
    gapPosition: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Select",
      enum: [
        {
          label: "顶部",
          value: "top"
        },
        {
          label: "底部",
          value: "bottom"
        },
        {
          label: "左",
          value: "left"
        },
        {
          label: "右",
          value: "right"
        }
      ],
      "x-component-props": {
        defaultValue: "bottom",
      },
      "x-reactions": {
        dependencies: ['.type'],
        fulfill: {
          state: {
            visible: "{{$deps[0] == 'dashboard'}}",
          }
        }
      }
    }
  },
};
