import React from "react";
import { Input } from "antd";
import { useExpressionScope } from "@formily/react";
//@ts-ignore
import SelectUvFormilyDialog from "@/pages/MainPage/dialogs/SelectUvByFormilyDialog.jsx";
//@ts-ignore
import { useOpenModal } from "@/components/Modal/Modal.jsx";

export default (props) => {
  const { value, onChange, pageType = "PAGE", ...otherProps } = props;
  const openDialog = useOpenModal();
  const $scope = useExpressionScope();
  //页面跳转的界面视图选择
  const handlePageSelPage = async () => {
    const res = await openDialog(
      <SelectUvFormilyDialog
        queryParams={{
          prjId: $scope.prjData.prjId,
          sVer: $scope.prjData.sVer,
          uvType: $scope.uvType,
          pageType,
        }}
        value={"默认选中值"}
      />
    );
    if (res && res[0] && res[0].pkid) {
      const item = res[0];
      onChange({
        pageId: item.pkid,
        pageName: `${item.uiName}${!!item.uiName && "-"}${item.uvName}-${
          item.dpSn
        }`,
      });
    }
  };

  return (
    <div>
      <Input.Search
        readOnly
        value={value.pageName}
        placeholder="请选择"
        onSearch={handlePageSelPage}
        {...otherProps}
      />
      <Input hidden value={value.pageId} />
    </div>
  );
};
