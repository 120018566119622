//@ts-ignore
import openWindow from '@/script/openWindow.mjs'
import { oldDataCompatibility } from '../../Formily/service/utils'

// 从文本域中转化json
export { getOptions } from '../../Formily/service/utils'

export const clickEventToProps = (clickEventProps, eventName= 'onClick') => {
    let props: any = {};
    let newClickEventProps: any = oldDataCompatibility(clickEventProps)
    const { actionType = '', target = false, targetPage, href="" } = newClickEventProps.defaultAction;
    const targetType =  target ? `_blank` : '_self';
    if (actionType.includes("link")) {
      if(targetType == '_self') {
        props[eventName] = () => window.top.location.href = (href); 
      } else {
        props[eventName] = () => window.top.open(href, targetType); 
      }
      
      // openWindow(href, {}, targetType)
    }
    if (actionType.includes('page')) {
      if(targetType == '_self') {
        props[eventName] = () => window.parent.postMessage({ type: 'linkPage', data: {uvId: targetPage.pageId} }, '*'); 
      } else {
        props[eventName] = () => openWindow('/formilyPreview', { uvId: targetPage.pageId }, targetType)
      }
    }
    return props;
}

// menu菜单items处理专用
export const removeEmptyChildren = (datas) => {
  if (!datas || datas.length == 0) return [datas, []];
  const filterArr = (arr) => {
    arr.forEach(node => {
      if (node.children && node.children.length === 0) {
        delete node.children; // 删除空children属性
      } else if (node.children && node.children.length > 0) {
        filterArr(node.children)
      }
    });
    return arr;
  }
  return filterArr(datas)
}

export const ctrlClickEvent = (obj: any) => {
  // 通过 postmessage将 obj 传递出去
  window.parent.postMessage({ type: 'ctrlClick', data: obj }, '*');
}