export const Swiper = {
  'zh-CN': {
    title: '走马灯',
    settings: {
      'x-component-props': {
        items: '数据',
        allowTouchMove: '手势滑动',
        autoplay: '自动播放',
        autoplayInterval: '间隔时间',
        direction: '方向',
        loop: '是否循环',
        defaultIndex: '初始位置',
        height: '高度'
      },
    },
  },
}
