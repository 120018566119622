import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Tag as AntdTag } from 'antd'
import type { TagProps } from 'antd/es/tag'
import React from 'react'

export interface FormilyTagProps extends TagProps {}
const FormilyTag: ReactFC<FormilyTagProps> = connect(
  AntdTag,
)

export default FormilyTag
