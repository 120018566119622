export const TextArea = {
  'zh-CN': {
    title: '文本域',
    settings: {
      'x-component-props': {
        maxLength: '最大长度',
        autoSize: {
          title: '自适应高度',
          tooltip: '可设置为 true | false 或对象：{ minRows: 2, maxRows: 6 }',
        },
        showCount: '是否展示字数',
        checkStrength: '检测强度',
        rows: '行数',
      },
    },
  },
}
