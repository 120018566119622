import React from 'react'
import FormilyTextArea from './TextArea'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const TextArea: DnFC<React.ComponentProps<typeof FormilyTextArea>> =
  FormilyTextArea


TextArea.Behavior = createBehavior(
  {
    name: 'TextArea',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'TextArea',
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.TextArea),
    },
    designerLocales: AllLocales.TextArea,
  },
)

TextArea.Resource = createResource(
  {
    icon: 'TextAreaSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'string',
          title: 'TextArea',
          'x-decorator': 'FormItem',
          'x-component': 'TextArea',
          'x-component-props': {
            placeholder: '请选择',
          },
        },
      },
    ],
  },
)
