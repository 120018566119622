import React from "react";
import { useField, useForm, connect } from "@formily/react";
import { createBehavior, createResource } from "@designable/core";
import AntdNavBar, { FormilyNavBarProps } from './NavBar'
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";


export const NavBar: DnFC<FormilyNavBarProps> =  connect(
  AntdNavBar,
)

NavBar.Behavior = createBehavior({
  name: "NavBar",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "NavBar",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.NavBar),
  },
  designerLocales: AllLocales.NavBar,
});

NavBar.Resource = createResource({
  icon: "NavBarSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "NavBar",
        "x-component-props": {
          children: '标题'
        },
      },
    },
  ],
});