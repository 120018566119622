export const Button = {
  'zh-CN': {
    title: '按钮',
    settings: {
      'x-component-props': {
        text: '标题',
        type: {
          title: '按钮类型',
          dataSource: ['主按钮', '次按钮', '虚线按钮', '文本按钮', '链接按钮'],
        },
        size: {
          title: '按钮大小',
          dataSource: ['大尺寸', '默认尺寸', '小尺寸'],
        },
        block: '撑满宽度',
        danger: '设置危险按钮',
        disabled: '失效状态',
        ghost: '幽灵模式',
        clickEventProps: '事件',
        // searchArr: '搜索字段',
        mapParmsToProps: '数据映射'
      },
    },
  },
  'en-US': {
    title: '按钮',
    settings: {
      'x-component-props': {
        danger: '设置危险按钮',
        disabled: '失效状态',
        ghost: '幽灵模式',
        href: '点击跳转的地址',
      },
    },
  },
  'ko-KR': {
    title: '입력',
    settings: {
      'x-component-props': {
        danger: '设置危险按钮',
        disabled: '失效状态',
        ghost: '幽灵模式',
        href: '点击跳转的地址',
      },
    },
  },
}
