import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Divider, DividerProps } from 'antd-mobile'
// import type { DividerProps } from 'antd/es/divider'
import React from 'react'

export interface FormilyDividerProps extends DividerProps {
  children?: React.ReactNode
}
const AntdDivider = ({children, ...props}: FormilyDividerProps) => {
  return <Divider {...props} >{props.direction == 'horizontal' ? children : null}</Divider>
}

const FormilyDivider: ReactFC<FormilyDividerProps> = connect(
  AntdDivider,
)

export default FormilyDivider
