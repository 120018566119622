import { createBehavior, createResource } from "@designable/core";
import { AntdList, FormilyListProps } from "./List"; 
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";

export const List: DnFC<FormilyListProps> =  (props) => {
  return (<div 
    data-designer-node-id={props["data-designer-node-id"]}
  >
    <AntdList {...props} />
  </div>)
};

List.Behavior = createBehavior({
  name: "List",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "List",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.List),
  },
  designerLocales: AllLocales.List,
});

List.Resource = createResource({
  icon: "ListSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "List",
        "x-component-props": {
          items: [
            {
              key: 'list31',
              name: '用户1',
              description: '我是一段简单介绍',
              prefixType: 'image',
              prefixSrc: ''
            },
            {
              key: 'list32',
              name: '用户2',
              description: '我是一段简单介绍',
              prefixType: 'image',
              prefixSrc: ''
            },
            {
              key: 'list33',
              name: '用户3',
              description: '我是一段简单介绍',
              prefixType: 'image',
              prefixSrc: ''
            },
          ],
          header: 'demo列表',
          action: true,
          rightActions: [
            {
              key: 'delete',
              text: '删除',
              color: 'danger',
            }
          ]
        },
      },
    },
  ],
});