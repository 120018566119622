import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Affix } from 'antd'
import type { AffixProps } from 'antd/es/affix'
import './styles.less'
import React from 'react'

export interface FormilyAffixProps extends AffixProps {
  type: 'top' | 'bottom'
}

const AntdAffixMobile = ({ children, offsetBottom, offsetTop, type='top', ...props}: FormilyAffixProps) => {
  if (type == 'top') {
    return (<Affix offsetTop={offsetTop} target={()=> {return document.querySelector('.formilyMobileRoot')}} {...props}>{children}</Affix>)
  }
  if (type == 'bottom') {
    return (<Affix offsetBottom={ offsetBottom} {...props}>{children}</Affix>)
  }
  
  // return React.createElement(Affix, { offsetTop, }, children)
}

const AntdAffix = ({ children, ...props}: FormilyAffixProps) => {
  return (<Affix target={()=> {return document.querySelector('.formily-preview-content')}} {...props} >{children}</Affix>)
}



export const FormilyAffix: ReactFC<FormilyAffixProps> = connect(
  AntdAffix,
)

export const FormilyAffixMobile: ReactFC<FormilyAffixProps> = connect(
  AntdAffixMobile,
)

