export const Button = {
  'zh-CN': {
    title: '按钮',
    settings: {
      'x-component-props': {
        text: '标题',
        type: {
          title: '原生类型',
          dataSource: ['提交', '重置', '普通按钮'],
        },
        color: {
          title: '按钮类型',
          dataSource: ['普通按钮', '主按钮', '成功按钮', '警告按钮', '危险按钮'],
        },
        size: {
          title: '按钮大小',
          dataSource: ['大尺寸', '默认尺寸', '小尺寸', '迷你尺寸'],
        },
        block: '撑满宽度',
        danger: '设置危险按钮',
        disabled: '失效状态',
      },
    },
  },
}
