export const Void = {
  'zh-CN': {
    title: '虚拟容器',
  },
  'en-US': {
    title: 'Void',
  },
  'ko-KR': {
    title: '비어있음',
  },
}
