import { ISchema } from '@formily/react'

export const Divider: ISchema = {
  type: "object",
  properties: {
    direction: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-component-props": {
        defaultValue: "horizontal",
        optionType: "button",
      },
    },
    children: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        placeholder: "请输入",
        allowClear: true,
      },
      "x-reactions": {
        dependencies: [".direction"],
        fulfill: {
          state: {
            hidden: '{{$deps[0] == "vertical"}}',
          },
        },
      },
    },
    contentPosition: {
      type: "string",
      title: "内容位置",
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-component-props": {
        optionType: "button",
        buttonStyle: "solid",
        defaultValue: 'center'
      },
      enum: ['left', 'center', 'right'],
      "x-reactions": {
        dependencies: [".direction"],
        fulfill: {
          state: {
            hidden: '{{$deps[0] == "vertical"}}',
          },
        },
      },
    }
  },
};
