export const Tree = {
  'zh-CN': {
    title: '树形控件',
    settings: {
      'x-component-props': {
        treeData: '数据',
        checkable: '复选框',
        defaultExpandAll: '自动展开父节点',
        iconName: '图标',
        disabled: '禁用'
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: '颜色'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}
