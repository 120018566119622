import React from "react";
import { FormilyAffixProps } from "./Affix";
import { createBehavior, createResource } from "@designable/core";
import { DnFC, useTreeNode,  DroppableWidget } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import "./styles.less";

export const Affix: DnFC<FormilyAffixProps> = ({
  children,
  ...props
}) => {
  const node = useTreeNode() 
  
  if(node?.children?.length == 0) {
    return (
      <div 
        data-designer-node-id={props["data-designer-node-id"]}
        style={{ ...props.style}}
      >
        <DroppableWidget />
      </div>
    )
  }
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-affix"
      style={{ ...props.style }}
    >
      {children}
    </div>
  );
};

Affix.Behavior = createBehavior({
  name: "Affix",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Affix",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Affix),
    droppable: true,
    allowDrop: (node) => node.props['x-component'] !== 'Affix',
  },
  designerLocales: AllLocales.Affix,
});

Affix.Resource = createResource({
  icon: "AffixSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Affix",
        "x-component-props": {
          offsetTop: 0,
        },
      },
    },
  ],
});