import React from 'react'
import { SearchBar as AntdSearchBar } from 'antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const SearchBar: DnFC<React.ComponentProps<typeof AntdSearchBar>> = AntdSearchBar

SearchBar.Behavior = createBehavior({
  name: 'SearchBar',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'SearchBar',
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.SearchBar),
  },
  designerLocales: AllLocales.SearchBar,
})

SearchBar.Resource = createResource({
  icon: 'SearchBarSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'number',
        'x-component': 'SearchBar',
        'x-component-props': {
          placeholder: '请输入'
        }
      },
    },
  ],
})
