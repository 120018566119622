import React from 'react';
import {clone} from '@formily/shared'
import * as icons from "antd-mobile-icons";
import { Button } from 'antd'
import cls from "classnames";
import './style.less'
import { CloseCircleOutlined } from "@ant-design/icons";
//@ts-ignore
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import IconSetterModal from './IconSetterModal'

export interface IIconSelSetterProps {
    value?: string
    onChange?: (value) => void
}

export const IconSelect: React.FC<IIconSelSetterProps> = (props) => {
    const openModal = useOpenModal();
    const copyVal = clone(props.value);

    const handleSetterClick = async () => {
        //@ts-ignore
        const res = await openModal(<IconSetterModal value={copyVal}/>)
        if (res) {
            const {value} = res
            props.onChange(value);
        }
    }

    return (
        <div style={{ width: '100%' , display: 'flex'}}>
            <Button
                //@ts-ignore
                className={cls({
                    "dn-btn-suc": copyVal?.actionType,
                })}
                style={{ flex: 1}}
                onClick={handleSetterClick}
            >
                {copyVal ? '重新配置' : '配置'}
            </Button>
            {copyVal && (
                <Button
                style={{ width: '32px'}}
                onClick={() => props.onChange(undefined)}
                //@ts-ignore
                icon={<CloseCircleOutlined />}
                ></Button>
            )}
        </div>
        // <>
        //     <a
        //         style={{
        //             marginRight: 4,
        //         }}
        //         onClick={handleSetterClick}

        //     >
        //         {copyVal ? '重新配置' : '配置'}
        //     </a>
        //     {
        //         copyVal && (
        //             <a
        //                 onClick={() => props.onChange(undefined)}
        //                 style={{
        //                     color: '#FF7875',
        //                     transform: 'opacity ease-in-out 0.2s'
        //                 }}
        //             >
        //                 清除
        //             </a>
        //         )
        //     }
        // </>
    )
}

export const Icon = (props: { name: string, style?: any }) => {
    const {name = 'AppstoreOutline', style = {fontSize: '18px'}, ...otherProps} = props
    const antIcon: { [key: string]: any } = icons
    const componentName = antIcon[name]
    return React.createElement(componentName, {style: style, ...otherProps})
}

  

