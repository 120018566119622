import React, { useState, useEffect } from 'react'
import { TreeNode, createBehavior, createResource, SelectNodeEvent } from '@designable/core'
import {
  DnFC,
} from '@designable/react'
import { observer } from '@formily/reactive-react'
import AntdSteps, {FormilyStepsProps} from './Steps'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'

export const Steps: DnFC<FormilyStepsProps> = AntdSteps;

Steps.Behavior = createBehavior(
  {
    name: 'Steps',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Steps',
    designerProps: {
      propsSchema: createBaseSchema(AllSchemas.Steps),
    },
    designerLocales: AllLocales.Steps,
  },
)

Steps.Resource = createResource({
  icon: 'StepsSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Steps',
        'x-component-props': {
          steps: [
            {
              title: '步骤一',
              key: 'step1',
              description: '这里是描述',
            },
            {
              title: '步骤二',
              key: 'step2',
              description: '这里是描述',
            },
            {
              title: '步骤三',
              key: 'step3',
              description: '这里是描述',
            },
          ],
        }
      },
    },
  ],
})