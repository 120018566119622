export const Menu = {
  'zh-CN': {
    title: '菜单导航',
    settings: {
      'x-component-props': {
        items: '菜单内容',
        mode: {
          title: '菜单类型',
          dataSource: [
            { label: '水平', value: 'horizontal' },
            { label: '垂直', value: 'vertical' },
            { label: '内嵌', value: 'inline' },
          ],
        },
        theme: {
          title: '主题',
          dataSource: [
            { label: '白色', value: 'light' },
            { label: '暗黑', value: 'dark' },
          ],
        },
      },
    },
  },
  'en-US': {
    title: '图片',
    settings: {
      'x-component-props': {
        items: '菜单内容',
      },
    },
  },
  'ko-KR': {
    title: '图片',
    settings: {
      'x-component-props': {
        items: '菜单内容',
      },
    },
  },
}
