import React from 'react'
import { Card as AntdCard } from 'antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC, DroppableWidget } from '@designable/react'
import { createBaseSchema } from '../Field'

export const Card: DnFC<React.ComponentProps<typeof AntdCard>> = (props) => {
  return (
    <AntdCard
      {...props}
      title={
        props.title ? 
        <span>
          {props.title}
        </span>
        : null
      }
    >
      {props.children || <DroppableWidget />}
    </AntdCard>
  )
}

Card.Behavior = createBehavior({
  name: 'Card',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Card',
  designerProps: {
    droppable: true,
    propsSchema: createBaseSchema({
      type: 'object',
      properties: {
        title: {
          title: '标题',
          type: 'string',
          'x-decorator': 'FormItem',
          'x-component': 'Input',
        },
      },
    }),
  },
  designerLocales: {
    'zh-CN': {
      title: '卡片',
    }
  },
})

Card.Resource = createResource({
  icon: 'CardSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Card',
        'x-component-props': {
          title: 'Title',
        },
      },
    },
  ],
})
