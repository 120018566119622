import { ISchema } from '@formily/react'
import {
  DataSourceSetter,
} from '@designable/formily-setters'

export const Selector: ISchema = {
  type: 'object',
  properties: {
    options: {
      title: '配置可选项',
      "x-decorator": 'FormItem',
      "x-component": DataSourceSetter
    },
    disabled: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    columns: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
    },
    multiple: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
  },
}

