import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Image as AntdImage } from 'antd'
import type { ImageProps } from 'antd/es/image'
import React from 'react'

export interface FormilyImageProps extends ImageProps {
  noPreview?: boolean
}
const FormilyImage: ReactFC<FormilyImageProps> = connect(
  AntdImage,
)

export default FormilyImage
