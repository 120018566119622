export const Switch = {
  'zh-CN': {
    title: '开关',
  },
  'en-US': {
    title: 'Switch',
  },
  'ko-KR': {
    title: '스위치',
  },
}
