import { ISchema } from '@formily/react'
import { ClickEventSetter } from '../../../Formily/src/setters'

export const NavBar: ISchema = {
  type: 'object',
  properties: {
    children: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
    },
    back: {
      title: '返回区域文字',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
    },
    backArrow: {
      title: '返回箭头',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
      "x-component-props": {
        defaultChecked: true
      }
    },
    'clickEventProps': {
      title: '返回箭头事件',
      type: 'Object',
      'x-decorator': 'FormItem',
      'x-component': ClickEventSetter,
      "x-component-props": {
        senceType: 'modal'
      },
      'x-reactions': [
        {
          dependencies: ['.backArrow', '.back'],
          fulfill: {
            state: {
              visible: '{{$deps[0] || $deps[1]}}'
            }
          }
        }
      ]
    },
  },
}

