import { ISchema } from '@formily/react'
// import {
//   ReactionsSetter,
//   DataSourceSetter,
//   ValidatorSetter,
// } from '@designable/formily-setters'
import { FormItemSwitcher } from '../../common/FormItemSwitcher'
import { AllSchemas } from '../../schemas'

export const createComponentSchema = (
  component: ISchema,
  decorator: any
) => {
  return {
    'component-group': component && {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-component"]}}',
          },
        },
      },
      properties: {
        'x-component-props': component,
      },
    },
    'component-style-group': {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-component-props': { defaultExpand: false },
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-component"]}}',
          },
        },
      },
      properties: {
        'x-component-props.style': AllSchemas.CSSStyle,
      },
    },
    'decorator-group': decorator && {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-component-props': { defaultExpand: false },
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-decorator"]}}',
          },
        },
      },
      properties: {
        'x-decorator-props': decorator,
      },
    },
    'decorator-style-group': {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-component-props': { defaultExpand: false },
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-decorator"]}}',
          },
        },
      },
      properties: {
        'x-decorator-props.style': AllSchemas.CSSStyle,
      },
    },
  }
}

export const createFieldSchema = (
  component?: ISchema,
  decorator: ISchema = AllSchemas.FormItem,
  exCludes: string[] = []
): ISchema => {
  return {
    type: 'object',
    properties: {
      'field-group': {
        type: 'void',
        'x-component': 'CollapseItem',
        properties: {
          // name: {
          //   type: 'string',
          //   'x-decorator': 'FormItem',
          //   'x-component': 'Input',
          // },
          // 'x-display': {
          //   type: 'string',
          //   enum: ['visible', 'hidden', 'none', ''],
          //   'x-decorator': 'FormItem',
          //   'x-component': 'Select',
          //   'x-component-props': {
          //     defaultValue: 'visible',
          //   },
          // },
          title: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
          },
          description: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input.TextArea',
          },
          'x-pattern': !exCludes.includes('pattern') && {
            type: 'string',
            enum: ['editable', 'disabled', 'readOnly', 'readPretty', ''],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
              defaultValue: 'editable',
            },
          },
          default: !exCludes.includes('default') && {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
          },
          // enum: {
          //   'x-decorator': 'FormItem',
          //   'x-component': DataSourceSetter,
          // },
          // 'x-reactions': {
          //   'x-decorator': 'FormItem',
          //   'x-component': ReactionsSetter,
          // },
          // 'x-validator': {
          //   type: 'array',
          //   'x-component': ValidatorSetter,
          // },
          // required: {
          //   type: 'boolean',
          //   'x-decorator': 'FormItem',
          //   'x-component': 'Switch',
          // },
        },
      },
      ...createComponentSchema(component, decorator),
    },
  }
}

export const createVoidFieldSchema = (
  component?: ISchema,
  decorator: ISchema = AllSchemas.FormItem,
  arr: string[] = []
) => {
  return {
    type: 'object',
    properties: {
      'field-group': {
        type: 'void',
        'x-component': 'CollapseItem',
        properties: {
          title: !arr.includes("title") && {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
          },
          // description: {
          //   type: 'string',
          //   'x-decorator': 'FormItem',
          //   'x-component': 'Input.TextArea',
          // },
          'x-pattern': {
            type: 'string',
            enum: ['editable', 'disabled'],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
              defaultValue: 'editable',
            },
          },
          // default: {
          //   'x-decorator': 'FormItem',
          //   'x-component': 'ValueInput',
          // },
          // 'x-decorator': {
          //   type: 'string',
          //   'x-decorator': 'FormItem',
          //   'x-component': FormItemSwitcher,
          // },
        },
      },
      ...createComponentSchema(component, decorator),
    },
  }
}

// 新增一个没有展示状态与 UI 形态的组件配置
export const createNormalSchema = (
  component?: ISchema,
  decorator: ISchema = AllSchemas.FormItem
) => {
  return {
    type: 'object',
    properties: {
      'field-group': {
        type: 'void',
        'x-component': 'CollapseItem',
        properties: {
          title: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input',
          },
          description: {
            type: 'string',
            'x-decorator': 'FormItem',
            'x-component': 'Input.TextArea',
          },
          'x-pattern': {
            type: 'string',
            enum: ['editable', 'disabled', 'readOnly', 'readPretty', ''],
            'x-decorator': 'FormItem',
            'x-component': 'Select',
            'x-component-props': {
              defaultValue: 'editable',
            },
          },
          default: {
            'x-decorator': 'FormItem',
            'x-component': 'ValueInput',
          },
        },
      },
      ...createComponentSchema(component, decorator),
    },
  }
}

// 新增一个没有字段属性的空属性组件配置
export const createBaseComponentSchema = (
  component: ISchema,
  extraSchema: ISchema = {}
) => {
  return {
    'component-group': component && {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-component"]}}',
          },
        },
      },
      properties: {
        'x-component-props': component,
      },
    },
    'extra-group': extraSchema && {
      ...extraSchema
    },
    'component-style-group': {
      type: 'void',
      'x-component': 'CollapseItem',
      'x-component-props': { defaultExpand: !component },
      'x-reactions': {
        fulfill: {
          state: {
            visible: '{{!!$form.values["x-component"]}}',
          },
        },
      },
      properties: {
        'x-component-props.style': AllSchemas.CSSStyle,
      },
    },
  }
}
export const createBaseSchema = (
  component?: ISchema,
  // decorator: ISchema = AllSchemas.FormItem
  extraSchema?: any
) => {
  return {
    type: 'object',
    properties: {
      ...createBaseComponentSchema(component, extraSchema),
    },
  }
}
