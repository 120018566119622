import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyTree, { FormilyTreeProps } from "./Tree"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { Icon } from "../../setters";
import "./styles.less";

interface FormilyITreeProps extends FormilyTreeProps {
  iconName?: string;
}

export const Tree: DnFC<FormilyITreeProps> = ({
  iconName,
  ...props
}) => {
  if (iconName) {
    props.icon = <Icon name={iconName} style={{fontSize: '12px'}}/>
    props.showIcon = true
  }
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-tree"
    >
      <FormilyTree {...props} 
        fieldNames={{ title: 'label', key: 'value', children: 'children'}}
        showLine={true}
      ></FormilyTree>
    </div>
  );
};

Tree.Behavior = createBehavior({
  name: "Tree",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Tree",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Tree),
  },
  designerLocales: AllLocales.Tree,
});

Tree.Resource = createResource({
  icon: "TreeSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Tree",
        "x-component-props": {
          treeData: [
            {
              label: '选项1',
              value: 'tree1'
            }
          ]
        },
      },
    },
  ],
});