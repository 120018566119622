import { ISchema } from '@formily/react'
import { ClickEventSetter, ImageInput } from '../../../Formily/src/setters'

export const Image: ISchema = {
  type: 'object',
  properties: {
    src: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': ImageInput,
      "x-validator": 'url',
      "x-component-props": {
        placeholder: '上传或输入图片地址'
      }
    },
    // noPreview: {
    //   type: 'string',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'Switch',
    //   description: "预览与事件冲突"
    // },
    // 'clickEventProps': {
    //   type: 'Object',
    //   'x-decorator': 'FormItem',
    //   'x-component': ClickEventSetter,
    //   "x-component-props": {
    //     senceType: 'button'
    //   },
    //   "x-reactions": {
    //     dependencies: ['.noPreview'],
    //     fulfill: {
    //       state: {
    //         visible: "{{!!$deps[0]}}",
    //       }
    //     }
    //   },
    // },
  },
}
