import React from "react";
import { useField, useForm, connect } from "@formily/react";
import { createBehavior, createResource } from "@designable/core";
import { AntdTabBar, FormilyTabBarProps } from "./tabBar"; 
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";

export const TabBar: DnFC<FormilyTabBarProps> =  AntdTabBar;

TabBar.Behavior = createBehavior({
  name: "TabBar",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "TabBar",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.TabBar),
  },
  designerLocales: AllLocales.TabBar,
});

TabBar.Resource = createResource({
  icon: "TabBarSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "TabBar",
        "x-component-props": {
          tabs: [
            {
              key: 'home',
              title: '首页',
              iconName: "AppOutline",
            },
            {
              key: 'todo',
              title: '待办',
              iconName:  "UnorderedListOutline",
              badge: '2'
            },
            {
              key: 'message',
              title: '消息',
              iconName: "MessageOutline",
              badge: '99+'
            },
            {
              key: 'me',
              title: '我的',
              iconName: "UserOutline",
            },        
          ]
        },
      },
    },
  ],
});