export const SearchBar = {
  'zh-CN': {
    title: '搜索框',
    settings: {
      'x-component-props': {
        cancelText: '取消按钮文案',
        clearOnCancel: '按钮清空',
        clearable: '启用清除图标',
        onlyShowClearWhenFocus: '仅在聚焦时显示',
        placeholder: '提示文本',
        showCancelButton: '是否显示取消按钮'
      },
    },
  },
}
