import { connect, mapProps, mapReadPretty } from '@formily/react'
import { Checkbox as AntdCheckbox } from 'antd'
import { PreviewText } from '@formily/antd'

const InternalCheckbox = connect(
  AntdCheckbox,
  mapProps({
    value: 'checked',
    onInput: 'onChange',
  })
)

const Group = connect(
  (props) => {    
    //@ts-ignore
    return <AntdCheckbox.Group {...props} value={typeof props.value == 'number' ? String(props.value) : props.value} />
  },
  mapProps({
    dataSource: 'options',
  }),
  mapReadPretty(PreviewText.Select, {
    mode: 'tags',
  })
)

export const Checkbox = Object.assign(InternalCheckbox, {
  __ANT_CHECKBOX: true,
  Group,
})

export default Checkbox