import React, { useEffect, useState } from 'react'
import { useField, observer } from '@formily/react'
import { InputNumber } from 'antd'
import { InputItems, SizeInput, FoldItem } from '@designable/react-settings-form'
import './style.less'

export const GridLayoutColumnsSetter: React.FC<any> = observer((props) => {

    const [ list, setList ] = useState<any>([]);

    const [count, setCount] = useState<any>();

    const { value } = props;

    const field = useField()
    
    useEffect(() => {
        if (value) {
            let list = value.split(" ");
            setCount(list.length);
            const tmp = [];
            for(let i=0; i<list.length; i++){
                tmp.push(i);
            }
            setList(tmp);
            props.onChange?.(value);
        }
    }, [])

    const createGridLayoutColumnsOnChange = (position: number) => {
        return {
            value: value ? position < 0 ? value.split(" ").length : value.split(" ")[position] : 1,
            onChange: (val: any) => {
                if(value){
                    let list = value.split(" ");
                    list[position] = (val);
                    props.onChange?.(list.join(" "));
                }else{
                    props.onChange?.(undefined)
                }
            },
        }
    }

    const countOnChange = (val: number) => {
        const list: any[] = [];
        let str = '';
        for(let i=0; i<val; i++){
            list.push(i);
            str +=  i == 0 ? `auto` : ` auto`;
        }
        props.onChange?.(str);
        setList(list);
        setCount(val);
    }

    return (
        <FoldItem className="gird-layout-columns-fold-item" label={field.title}>
            <FoldItem.Base>
                <InputNumber precision={0}
                  placeholder='请输入数字'
                    value={count}
                    onChange={(val) => {
                        countOnChange((val || 0) as number);
                    }}
                />
            </FoldItem.Base>

            <FoldItem.Extra>
                {
                    list && list.length ?
                        <InputItems>
                            {
                                list?.map((item: number, index: number) => {
                                    return (
                                        <>
                                        <span style={{ display: 'block', padding: '2px 4px'}}>第{index+1}列宽度</span>
                                        <InputItems.Item key={item}>
                                            <SizeInput
                                                {...createGridLayoutColumnsOnChange(index)}
                                                include={['px', 'auto', '%']}
                                            />
                                        </InputItems.Item>
                                        </>
                                    )
                                })
                            }
                        </InputItems>
                        :
                        null
                }
            </FoldItem.Extra>
        </FoldItem>
    )
});
