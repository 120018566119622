import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { SideBar, SideBarProps, SideBarItemProps } from 'antd-mobile'
import React from 'react'
import { clickEventToProps } from '../../../service/utils'
import { Icon } from '../Icon/Icon'

interface FormilyItemProps  extends SideBarItemProps {
  key?: string
  iconName: string
}
export interface FormilySideBarProps extends SideBarProps {
  tabs?: FormilyItemProps[]
}
const FormilySideBar = ({tabs, ...props}: FormilySideBarProps) => {
  return (
    <SideBar {...props}>
      {tabs?.map((item) => (
        <SideBar.Item
          key={item.key}
          title={item.title}
          badge={item.badge}
          disabled={item.disabled}
        />
      ))}
    </SideBar>
  );
}

export const PreviewSideBar = ({tabs, ...props}: FormilySideBarProps) => {
  const handleChange = (key) => {
    const result = tabs.filter(item => item.key === key);
    const clickEventProps = result[0]['clickEventProps'];
    let changeEvent = clickEventProps ? clickEventToProps(clickEventProps) : {};
    if (changeEvent.onClick) changeEvent.onClick();
  }
  return (
    <SideBar {...props} onChange={handleChange} >
      {tabs?.map((item) => (
        <SideBar.Item
          key={item.key}
          title={item.title}
          badge={item.badge}
          disabled={item.disabled}
        />
      ))}
    </SideBar>
)}

export const AntdSideBar: ReactFC<FormilySideBarProps> = connect(
  FormilySideBar,
)

export default AntdSideBar
