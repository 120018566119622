import { message } from 'antd';
import { clone, uid } from '@designable/shared';
//@ts-ignore
import openWindow from '@/script/openWindow.mjs'
import { toJS } from '@formily/reactive';

// 从文本域中转化json
export const getOptions = (value: string) => {
  const options: any [] = [];
  if(value){
      const list = value.split("\n");
      list.map((item: string) => {
          if(item){
                //@ts-ignore
              item = item.replaceAll('：', ':');
              const tmpArr = item.trim().split(":");
              if(tmpArr && tmpArr.length == 2){
                  options.push({
                      value: tmpArr[0].trim(),
                      label: tmpArr[1].trim()
                  })
              }
          }
      });
  }
  return options;
}

const handleClick = ($form, effectName, modalDetail, mapParmsToProps) => {
    if(!$form) return;
    let record = clone($form.values);
    if (mapParmsToProps) {
      const opts = getOptions(mapParmsToProps);
      for(let i in opts) {
        record[opts[i].label] = record[opts[i].value];
        delete record[opts[i].value];
      }
    }
    let modalParams = { modalDetailProps: modalDetail };
      if (modalDetail.withProps) {
        modalParams = {...modalParams,  ...record}
      }
    $form.notify('open-modal', modalParams)
  }

// 旧数据兼容处理
export const oldDataCompatibility = (clickEventProps) => {
  let newClickEventProps: any = {};
  if (clickEventProps && !clickEventProps?.defaultAction && !clickEventProps?.enableCase) {
    newClickEventProps.defaultAction = clone(clickEventProps);
  } else {
    return clickEventProps;
  }
  if (newClickEventProps.defaultAction?.modalDetail?.okBtnProps?.okCallbackUvId) {
    const pageId = newClickEventProps.defaultAction?.modalDetail?.okBtnProps?.okCallbackUvId;
    const pageName = newClickEventProps.defaultAction?.modalDetail?.okBtnProps?.okCallbackUvName
    newClickEventProps.defaultAction.modalDetail.okBtnProps.okCallbackPage = {
      pageId,
      pageName: pageId && !pageName ? `历史数据兼容：${pageId}` : pageName
    }
    delete newClickEventProps.defaultAction?.modalDetail?.okBtnProps?.okCallbackUvId
    delete newClickEventProps.defaultAction?.modalDetail?.okBtnProps?.okCallbackUvName
  }
  if (newClickEventProps.defaultAction?.modalDetail?.modalId) {
    const pageId = newClickEventProps.defaultAction?.modalDetail?.modalId;
    const pageName = newClickEventProps.defaultAction?.modalDetail?.modalName
    newClickEventProps.defaultAction.modalDetail.modalPage = {
      pageId,
      pageName: pageId && !pageName ? `历史数据兼容：${pageId}` : pageName
    }
    delete newClickEventProps.defaultAction?.modalDetail?.modalId;
    delete newClickEventProps.defaultAction?.modalDetail?.modalName;
  }
  if (newClickEventProps.defaultAction?.openPage) {
    const pageId = newClickEventProps.defaultAction?.openPage;
    const pageName = newClickEventProps.defaultAction?.openPageName;
    newClickEventProps.defaultAction.targetPage = {
      pageId,
      pageName: pageId && !pageName ? `历史数据兼容：${pageId}` : pageName
    }
    delete newClickEventProps.defaultAction?.openPageName;
    delete newClickEventProps.defaultAction?.openPage;
  }
  return newClickEventProps;
}

const propsToClickEvent = (actionDetail, form=undefined, mapParmsToProps='') => {
  let callBack = () => {};
  const { actionType = '', target = false, targetPage, modalDetail={}, messageDetail = {}, href="" } = actionDetail;
  const targetType =  target ? '_blank' : '_self';
  if (actionType.includes("link")) {
    callBack = () => openWindow(href, {}, targetType)
  }
  if (actionType.includes("message")) {
    const type = messageDetail.messageType || 'info'
    callBack = () => message[type](messageDetail.messageContent)
  }
  if (actionType == 'modal') {
    callBack = () => handleClick(form, 'open-modal', modalDetail, mapParmsToProps);
  }
  if (actionType.includes('page') && targetPage.pageId) { 
    callBack = () => openWindow('/formilyPreview', { uvId: targetPage.pageId }, targetType)
  }
  return callBack;
}

//compareCase
const compareCase = (deps, result, compare = 'eq') => {
  if (compare == 'eq') {
    return deps == result;
  } else if (compare == 'neq') {
    return deps != result;
  } else if (compare == 'gt') {
    return deps > result;
  } else if (compare == 'lt') {
    return deps < result;
  } else if (compare == 'gte') {
   return deps >= result;
  }else if (compare == 'include') {
    return deps?.includes(result);
  } else if (compare == 'exclude') {
   return !deps?.includes(result);
  } else if (compare == 'valid') {
    return !!deps;
  }  else if (compare == 'void') {
    return !deps;
  } else {
    return deps == result;
  }
}
export const clickEventToProps = (clickEventProps, form) => {
    let props: any = {};
    // let newClickEventProps: any = {};
    if (!clickEventProps?.enableCase) {
      // 兼容旧数据
    let newClickEventProps: any = oldDataCompatibility(clickEventProps)
      props.onClick = () => {
        const callBack = propsToClickEvent(newClickEventProps.defaultAction, form);
        callBack && callBack();
      }
      
    }else {
      // 逻辑条件事件
      const caseAction = clickEventProps.caseAction;
      if (caseAction && caseAction.length > 0) {
        props.onClick = (e) => {
          caseAction.forEach(item => {
            const caseDetail = item.caseDetail ? item.caseDetail[0] : undefined;
            if(!caseDetail) return;
            const result = caseDetail.depsType == 'Switch' ? caseDetail.resultBol : caseDetail.resultStr;
            const fieldState = form.getFieldState(caseDetail.deps) || undefined;
            if(!fieldState) return;
            const depsValue = caseDetail.depsType == 'Switch' ? Boolean(fieldState[caseDetail.propetry]) : fieldState[caseDetail.propetry];
            const reson = compareCase(depsValue, result, caseDetail.compare)
            if (reson) {
              const callBack = propsToClickEvent(item.actionDetail, form);
              callBack && callBack();
            }
          });
        }
      }else {
        props.onClick = () => {}
      }
    }
    return props;
}

// menu菜单items处理专用
export const removeEmptyChildren = (datas) => {
  if (!datas || datas.length == 0) return [datas, []];
  let clickArr = [];
  const filterArr = (arr) => {
    arr.forEach(node => {
      if (node.children && node.children.length === 0) {
        clickArr.push({
          clickProps: node.clickProps,
          key: node.key
        })
        delete node.children; // 删除空children属性
        delete node.clickProps
      } else if (node.children && node.children.length > 0) {
        delete node.clickProps
        filterArr(node.children)
      }
    });
    return arr;
  }
  const newArr = filterArr(datas)
  return [newArr, clickArr];
}

// 将树形数据转换为扁平化数组
// 递归处理一个数组，一个数组中有children属性，则只添加children属性的值
export const treeToArr = (arr: any[]) => {
  const newArr: any[] = [];
  arr.forEach((item: any) => {
    if (item.children && item.children.length > 0) {
      newArr.push(...treeToArr(item.children));
    } else {
      newArr.push(item);
    }
  });
  return newArr;
};

// 获取数组中所有children长度大于0的key
export const getArrChildrenKey = (arr: any[]) => {
  const newArr: any[] = [];
  arr.forEach((item: any) => {
    if (item.children && item.children.length > 0) {
      newArr.push(item.key);
    }
  });
  return newArr;
};

  // 获取数组中chiildren中包含key等于入参的key
  export const getOpenKeys = (arr: any[], currentKey) => {
    const newArr: any[] = [];
    arr.forEach((item: any) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach(jtem => {
          if(jtem.key == currentKey) {
            newArr.push(item.key);
          }
        });
      }
    });
    return newArr;
  };

// 复制的时候去重
function getAllIds(node) {
  let ids = [node.id];
  if (node.children && Array.isArray(node.children)) {
    node.children.forEach(child => {
      ids = ids.concat(getAllIds(child));
    });
  }
  return ids;
}

export const uniqueChildrenNode = (arrNodes) => {
  // const copyNodes = clone(arrNodes);
  let allNodesId = [];
  let result = [];

  arrNodes.forEach((item) => {
    if (!allNodesId.includes(item.id)) {
      allNodesId = allNodesId.concat(getAllIds(item))
      const newId = uid();
      const newProps = {
        ...toJS(item).props,
        'x-designable-id': newId,
      }
      const newItem = {
        ...item,
        id: newId,
        props: newProps
      }
      result.push(newItem)
    }
  });
  return result;
}