import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { TextArea as FormilyCom } from 'antd-mobile'
import type { TextAreaProps } from 'antd-mobile/es/components/text-area'

export interface FormilyAffixProps extends TextAreaProps {}
const FormilyTextArea: ReactFC<FormilyAffixProps> = connect(
  FormilyCom,
)

export default FormilyTextArea
