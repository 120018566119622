import { ISchema } from '@formily/react'
import { ItemSetter } from '../setters'
import { uid } from '@formily/shared'


export const Steps: ISchema & { StepItem?: ISchema } = {
  type: 'object',
  properties: {
    items: {
      type: 'array',
      'x-decorator': 'FormItem',
      'x-component': ItemSetter,
      "x-component-props": {
        // allowExtendOption: false,
        allowTree: false,
        defaultOptionValue: [
          {
            label: 'title',
            value: `步骤`,
          },
          {
            label: 'subTitle',
            value: '',
          },
          {
            label: 'description',
            value: `描述`,
          },
          {
            label: 'icon',
            value: '',
          }
        ]
      }
    },
    current: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      "x-decorator-props": {
        tooltip: "步骤条当前步骤，从 0 开始计数"
      },
      "x-component-props": {
        defaultValue: 0,
      }
    },
    direction: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      enum: [
        {
          label: '水平',
          value: 'horizontal',
        },
        {
          label: '垂直',
          value: 'vertical',
        },
      ],
      "x-component-props": {
        defaultValue: 'horizontal',
      }
    },
    type: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '导航',
          value: 'navigation',
        },
      ],
      "x-component-props": {
        defaultValue: 'default',
      }
    },
    labelPlacement: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      enum: [
        {
          label: '水平',
          value: 'horizontal',
        },
        {
          label: '垂直',
          value: 'vertical',
        },
      ],
      "x-component-props": {
        defaultValue: 'horizontal',
      }
    },
    size: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '迷你',
          value: 'small',
        },
      ],
      "x-component-props": {
        defaultValue: 'default',
      }
    },
    status: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [
        {
          label: 'wait',
          value: 'wait',
        },
        {
          label: 'process',
          value: 'process',
        },
        {
          label: 'finish',
          value: 'finish',
        },
        {
          label: 'error',
          value: 'error',
        },
      ],
      "x-component-props": {
        defaultValue: 'process',
        placeholder: '请选择',
        allowClear: true
      }
    },
    noLine: {
      type: 'boolean',
      title: '无线条',
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultChecked: false
      }
    }
  },
}
