import { connect, mapProps, mapReadPretty, ReactFC, useForm } from '@formily/react'
import React from 'react'
import { withContainer } from '../../common/Container'
import './styles.less'

export const Box = ({children, ...props}: any) => {
  return <div className='fromily-box' {...props} >{children}</div>
}

const FormilyBox: ReactFC<any> = withContainer(Box)

export default FormilyBox
