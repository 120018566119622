export const RowGrid = {
  'zh-CN': {
    title: '行式布局',
    addGridColumn: '添加网格行',
    settings: {
      'x-component-props': {
        columns: '列数',
        rowMargin: '行间距',
        columnMargin: '列间距',
      },
    },
  },
}

export const RowGridColumn = {
  'zh-CN': {
    title: '网格列',
    settings: {
      'x-component-props': {
        gridSpan: '跨列栏数',
      },
    },
  },
}
