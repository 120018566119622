import { ISchema } from '@formily/react'
import { StepSetter } from '../setters'
import { uid } from '@formily/shared'


export const Steps: ISchema & { StepItem?: ISchema } = {
  type: 'object',
  properties: {
    steps: {
      type: 'array',
      'x-decorator': 'FormItem',
      'x-component': StepSetter,
    },
    current: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      "x-decorator-props": {
        tooltip: "步骤条当前步骤，从 0 开始计数"
      },
      "x-component-props": {
        defaultValue: 0,
      }
    },
    direction: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      enum: [
        {
          label: '水平',
          value: 'horizontal',
        },
        {
          label: '垂直',
          value: 'vertical',
        },
      ],
      "x-component-props": {
        optionType: 'button',
        defaultValue: 'horizontal',
      }
    },
  },
}
