import { message } from 'antd';
import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'

export const Alert: ISchema = {
  type: "object",
  properties: {
    message: {
      type: "string",
      title: "内容",
      "x-decorator": "FormItem",
      "x-component": "Input",
      required: true,
    },
    type: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Select",
      enum: [
        {
          label: "成功",
          value: "success"
        },
        {
          label: "信息",
          value: "info"
        },
        {
          label: "警告",
          value: "warning"
        },
        {
          label: "错误",
          value: "error"
        }
      ],
      "x-component-props": {
        defaultValue: "warning",
      },
    },
    closable: {
      type: "boolean",
      title: "是否可关闭",
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    banner: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    description: {
      type: "string",
      title: "描述",
      "x-decorator": "FormItem",
      "x-component": "Input.TextArea",
      "x-component-props": {
        placeholder: "请输入描述",
      },
    },
    closeText: {
      type: "string",
      title: "关闭按钮文字",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        placeholder: "请输入关闭按钮文字",
      },
    },
    showIcon: {
      type: "boolean",
      title: "显示图标",
      "x-decorator": "FormItem",
      "x-component": "Switch",
    },
    // icon: {
    //   type: "string",
    //   title: "图标",
    //   "x-decorator": "FormItem",
    //   "x-component": IconSelect,
    //   "x-component-props": {
    //     placeholder: "请选择图标",
    //   },
    //   "x-reactions": {
    //     dependencies: [".showIcon"],
    //     fulfill: {
    //       state: {
    //         hidden: '{{!$deps[0]}}',
    //       },
    //     },
    //   },
    // }
  },
};
