import React from 'react'
import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { ProgressBar, ProgressBarProps } from 'antd-mobile'

export interface FormilyProgressBarProps extends ProgressBarProps {}
const PreviewProgressBar: ReactFC<FormilyProgressBarProps> = ({ percent, ...props}) => {
  return React.createElement(
    ProgressBar,
    {
      percent: percent || 0,
      ...props,
    }
  )
}
const FormilyProgressBar: ReactFC<FormilyProgressBarProps> = connect(
  PreviewProgressBar,
)

export default FormilyProgressBar
