import React, { useState, useEffect } from 'react'
import { createBehavior, createResource } from '@designable/core'
import {
  DnFC,
} from '@designable/react'
import { observer } from '@formily/reactive-react'
import FormilySteps, { FormilyStepsProps } from './Steps'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import './styles.less'
import classnames from 'classnames'
import { Icon } from "../../setters";

interface IdesignableGridProps extends FormilyStepsProps {
  style: any
  noLine?: boolean
}

const fillIcon = (arr) => {
  arr.forEach(item => {
    if (item.children) {
      item.children = fillIcon(item.children)
    }
    if (item.icon) {
      item.icon = <Icon name={item.icon} />
    }
  });
  return arr
}

export const Steps: DnFC<IdesignableGridProps> = observer(({ noLine, items, ...props }) => {
  const [newItems, setItems] = useState([]);
  useEffect(() => {
    const newItems = fillIcon(items)
    setItems(newItems)
  }, [items])

  return (
    <div data-designer-node-id={props["data-designer-node-id"]} className={classnames("dn-step", {'dn-step-noline': noLine})}>
      <FormilySteps
        items={newItems}
        {...props}
      ></FormilySteps>
    </div>
  );
})

Steps.Behavior = createBehavior(
  {
    name: 'Steps',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Steps',
    designerProps: {
      propsSchema: createBaseSchema(AllSchemas.Steps),
    },
    designerLocales: AllLocales.Steps,
  },
)

Steps.Resource = createResource({
  icon: 'StepsSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Steps',
        'x-component-props': {
          items: [
            {
              title: '步骤一',
              description: '这里是描述',
            },
            {
              title: '步骤二',
              description: '这里是描述',
            },
            {
              title: '步骤三',
              description: '这里是描述',
            },
          ],
        }
      },
    },
  ],
})