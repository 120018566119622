import { GlobalRegistry } from '@designable/core'
import zhCN from './zh-CN'
const enUS = {
  'en-US': {}
}

const koKR = {
  'ko-KR': {}
}

GlobalRegistry.registerDesignerLocales(zhCN, enUS, koKR)
