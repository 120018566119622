import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Steps as AntdSteps } from 'antd'
import type { StepsProps } from 'antd/es/steps'

export interface FormilyStepsProps extends StepsProps {}

const FormilySteps: ReactFC<StepsProps> = connect(
  AntdSteps,
)

export default FormilySteps