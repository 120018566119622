export const Slider = {
  'zh-CN': {
    title: '滑动条',
    settings: {
      'x-component-props': {
        ticks: '是否显示刻度',
        max: '最大值',
        min: '最小值',
        range: '双滑块',
        step: '步距'
      },
    },
  },
}
