// icon分类数据整合
// 网站通用图标-线框风格
export const iconsOutlined = [
  "AaOutline",
  "AddCircleOutline",
  "AddOutline",
  "AddSquareOutline",
  "AntOutline",
  "AppOutline",
  "AppstoreOutline",
  "ArrowDownCircleOutline",
  "ArrowsAltOutline",
  "AudioMutedOutline",
  "AudioOutline",
  "BankcardOutline",
  "BellMuteOutline",
  "BellOutline",
  "BillOutline",
  "CalculatorOutline",
  "CalendarOutline",
  "CameraOutline",
  "ChatAddOutline",
  "ChatCheckOutline",
  "ChatWrongOutline",
  "CheckCircleOutline",
  "CheckOutline",
  "CheckShieldOutline",
  "ClockCircleOutline",
  "CloseCircleOutline",
  "CloseOutline",
  "CloseShieldOutline",
  "CollectMoneyOutline",
  "CompassOutline",
  "ContentOutline",
  "CouponOutline",
  "DeleteOutline",
  "DownCircleOutline",
  "DownOutline",
  "DownlandOutline",
  "EditSOutline",
  "EnvironmentOutline",
  "ExclamationCircleOutline",
  "ExclamationOutline",
  "ExclamationShieldOutline",
  "ExclamationTriangleOutline",
  "EyeInvisibleOutline",
  "EyeOutline",
  "FaceRecognitionOutline",
  "FileOutline",
  "FileWrongOutline",
  "FillinOutline",
  "FilterOutline",
  "FingerdownOutline",
  "FlagOutline",
  "FolderOutline",
  "FrownOutline",
  "GiftOutline",
  "GlobalOutline",
  "HandPayCircleOutline",
  "HeartOutline",
  "HistogramOutline",
  "InformationCircleOutline",
  "KeyOutline",
  "KoubeiOutline",
  "LeftOutline",
  "LikeOutline",
  "LinkOutline",
  "LocationOutline",
  "LockOutline",
  "LoopOutline",
  "MailOpenOutline",
  "MailOutline",
  "MessageOutline",
  "MinusCircleOutline",
  "MinusOutline",
  "MoreOutline",
  "MovieOutline",
  "PayCircleOutline",
  "PhonebookOutline",
  "PictureOutline",
  "PictureWrongOutline",
  "PicturesOutline",
  "PieOutline",
  "PlayOutline",
  "QuestionCircleOutline",
  "ReceiptOutline",
  "ReceivePaymentOutline",
  "RedoOutline",
  "RightOutline",
  "ScanCodeOutline",
  "ScanningFaceOutline",
  "ScanningOutline",
  "SearchOutline",
  "SendOutline",
  "SetOutline",
  "ShopbagOutline",
  "ShrinkOutline",
  "SmileOutline",
  "SoundMuteOutline",
  "SoundOutline",
  "StarOutline",
  "StopOutline",
  "SystemQRcodeOutline",
  "TagOutline",
  "TeamOutline",
  "TextDeletionOutline",
  "TextOutline",
  "TransportQRcodeOutline",
  "TravelOutline",
  "TruckOutline",
  "UndoOutline",
  "UnlockOutline",
  "UnorderedListOutline",
  "UpCircleOutline",
  "UpOutline",
  "UploadOutline",
  "UserAddOutline",
  "UserCircleOutline",
  "UserContactOutline",
  "UserOutline",
  "UserSetOutline",
  "VideoOutline"
];


// 网站通用图标-实底风格
export const iconsFilled: string[] = [
  "AddressBookFill",
  "AlipayCircleFill",
  "AlipaySquareFill",
  "AudioFill",
  "CheckCircleFill",
  "CheckShieldFill",
  "ClockCircleFill",
  "CloseCircleFill",
  "DownFill",
  "EditFill",
  "EditSFill",
  "ExclamationCircleFill",
  "ExclamationShieldFill",
  "EyeFill",
  "EyeInvisibleFill",
  "FireFill",
  "ForbidFill",
  "FrownFill",
  "HeartFill",
  "InformationCircleFill",
  "KoubeiFill",
  "LocationFill",
  "LockFill",
  "MailFill",
  "MessageFill",
  "PhoneFill",
  "PhonebookFill",
  "QuestionCircleFill",
  "SmileFill",
  "SoundMuteFill",
  "StarFill",
  "TeamFill"
];

export const iconData = [
  {'title': '线框风格', key: 'outlined', icons: iconsOutlined},
  {'title': '实底风格', key: 'filled', icons: iconsFilled},
]

