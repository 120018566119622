export const Progress = {
  bar: {
    'zh-CN': {
      title: '进度条',
      settings: {
        'x-component-props': {
          percent: '百分比',
          rounded: '是否圆角',
          text: '是否显示进度文字',
        },
      },
    },
  },
  circle: {
    'zh-CN': {
      title: '进度圈',
      settings: {
        'x-component-props': {
          percent: '百分比',
          // rounded: '是否圆角',
          // text: '是否显示进度文字',
        },
      },
    },
  }
}
