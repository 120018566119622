import React, {useEffect, useState} from "react";
import Modal from "@/components/Modal/Modal";
import {iconData} from "./iconData"
import {Anchor, Button, Menu, Space, Typography} from "antd";
import {css} from "@emotion/react";
import Icon, * as antIcons from "@ant-design/icons";

const {Link} = Anchor;
const {Title} = Typography;

const cssTitle1 = css({
    fontSize: 20,
    fontWeight: 'bold',
})

const cssTitle2 = css({
    fontSize: 16,
    fontWeight: 'bold',
})

const cssGrid = css({
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    // gridTemplateRows: '1fr 1fr 1fr 1fr ',
    gap: 12,
})

const cssGridItem = css({
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    gap: 8,
    borderRadius: 4,
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0,183,244,0.85)',
        // backgroundColor: '#fafafa',
        color: 'white',
    }
})

const cssGridAcitveItem = css({
    backgroundColor: 'rgb(0,153,204)!important',
    color: 'white',
})

const styleIcon = {
    fontSize: 36,
}

const cssFooter = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

})

export default function IconSetterModal({value, onOk, ...props}) {
    const [selected, setSelected] = useState(null);
    const [data, setData] = useState({})
    const defaultKeys = [iconData[0].key + '-' + iconData[0].icons[0].key]

    const handleMenuClick = async ({key}) => {
        const keys = key.split('-')
        const primaryCatKey = keys[0]
        const secondaryCatKey = keys[1]
        const data = {}
        iconData.forEach(primaryCat => {
            if (primaryCat.key == primaryCatKey) {
                primaryCat.icons.forEach(secondaryCat => {
                    if (secondaryCat.key == secondaryCatKey) {
                        data.titles = [primaryCat.title, secondaryCat.title]
                        data.icons = secondaryCat.icons
                    }
                })
            }
        })

        setData(data)
    }

    const handleIconSelecteChange = async (key) => {
        if (key === selected) {
            setSelected(null)
        } else {
            setSelected(key)
        }
    }

    const handleOk = async () => {
        onOk({value: selected});
    }

    const handleCancel = async () => {
        onOk()
    }

    useEffect(() => {
        const keys = defaultKeys[0].split('-')
        const primaryCatKey = keys[0]
        const secondaryCatKey = keys[1]
        const data = {}
        iconData.forEach(primaryCat => {
            if (primaryCat.key == primaryCatKey) {
                primaryCat.icons.forEach(secondaryCat => {
                    if (secondaryCat.key == secondaryCatKey) {
                        data.titles = [primaryCat.title, secondaryCat.title]
                        data.icons = secondaryCat.icons
                    }
                })
            }
        })

        setData(data)
    }, [])

    return (
        <Modal
            title={"选择图标"}
            width={1280}
            style={{top: 20}}
            bodyStyle={{overflow: 'auto', maxHeight: "calc(80vh - 100px)", display: 'flex', flexDirection: 'column', minHeight: 320, background: '#fff', padding: 0}}
            onOk={handleOk}
            footer={
                <div css={cssFooter}>
                    <Space>
                        <span>当前应用图标:</span>
                        {
                            value && (
                                <Icon component={antIcons[value]}/>
                            )
                        }
                    </Space>
                    <Space>
                        <Button size="small" onClick={handleCancel}>取消</Button>
                        <Button size="small" type="primary" onClick={handleOk}>确定</Button>
                    </Space>
                </div>
            }
            {...props}
        >
            <div style={{display: 'flex', flexGrow: 1, overflow: 'hidden'}}>
                <div style={{display: 'flex', flexShrink: 0, overflow: 'auto', borderRight: '1px solid #f0f0f0',}}>
                    <Menu
                        style={{
                            width: 200,
                        }}
                        mode="inline"
                        defaultOpenKeys={[defaultKeys[0].split('-')[0]]}
                        defaultSelectedKeys={defaultKeys}
                        // selectedKeys={selectedKeys}
                        onClick={handleMenuClick}
                        items={iconData.map(primaryCat => {
                            return {
                                key: primaryCat.key,
                                label: primaryCat.title,
                                children: primaryCat.icons.map(secondCat => {
                                    return {
                                        key: primaryCat.key + '-' + secondCat.key,
                                        label: secondCat.title,
                                    }
                                })
                            }
                        })}
                    />
                </div>

                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto', padding: 12, gap: 12}}>
                    {
                        data.titles && (
                            <Space>
                                <span css={cssTitle1}>{data.titles[0]}</span>
                                <span css={cssTitle2}>{data.titles[1]}</span>
                            </Space>
                        )
                    }
                    {
                        data.icons && (
                            <div css={cssGrid}>
                                {
                                    data.icons.map(iconData => { 
                                        return (
                                            <div css={[cssGridItem, iconData == selected ? cssGridAcitveItem : null]} onClick={() => handleIconSelecteChange(iconData)}>
                                                <Icon component={antIcons[iconData]} style={styleIcon}/>
                                                <span>{iconData}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </Modal>
    );
}


