import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Ellipsis, EllipsisProps } from 'antd-mobile'
import React from 'react'

export interface FormilyEllipsisProps extends EllipsisProps {}
const FormilyEllipsis = ({ content, ...props}: FormilyEllipsisProps) => {
  return <Ellipsis {...props} content={content ? content : '请输入'} />
}

export const AntdEllipsis: ReactFC<FormilyEllipsisProps> = connect(
  FormilyEllipsis,
)

export default AntdEllipsis
