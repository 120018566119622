import React, { useContext } from 'react'
import { InputProps } from 'antd/lib/input'
import { Input, Upload } from 'antd'
import { usePrefix, IconWidget } from '@designable/react'
import cls from 'classnames'
// @ts-ignore
import useHTTP from "@/hooks/useHTTP.mjs";
import './styles.less'
export interface ImageInputProps extends Omit<InputProps, 'onChange'> {
  value?: string
  onChange?: (value: string) => void
}

export const ImageInput: React.FC<ImageInputProps> = ({
  className,
  style,
  ...props
}) => {
  const http = useHTTP();
  const prefix = usePrefix('image-input')
  const customRequest = (options: any) => {
    const { file } = options
    http.upload({file, pathName: '/formily'}).then((res) => {
      const fileId = res.fileId;
      if(!fileId) return;
      //@ts-ignore
      props.onChange?.(`${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`)
    })
  }
  return (
    <div className={cls(prefix, className)} style={style}>
      <Input
        {...props}
        onChange={(e) => {
          props.onChange?.(e?.target?.['value'])
        }}
        prefix={
          <Upload
            action="#"
            itemRender={() => null}
            maxCount={1}
            customRequest={customRequest}
          >
            <IconWidget infer="CloudUpload" style={{ cursor: 'pointer' }} />
          </Upload>
        }
      />
    </div>
  )
}

export const BackgroundImageInput: React.FC<ImageInputProps> = (props) => {
  const addBgValue = (value: any) => {
    if (/url\([^)]+\)/.test(value)) {
      return value
    }
    return `url(${value})`
  }
  const removeBgValue = (value: any) => {
    const matched = String(value).match(/url\(\s*([^)]+)\s*\)/)
    if (matched?.[1]) {
      return matched?.[1]
    }
    return value
  }
  return (
    <ImageInput
      value={removeBgValue(props.value)}
      onChange={(url) => {
        props.onChange?.(addBgValue(url))
      }}
    />
  )
}
