import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Image as AntdImage } from 'antd-mobile'
import type { ImageProps } from 'antd-mobile/es'
// import { IClickEventProps } from '../../../../Formily/src/components/Button'
// import { clickEventToProps } from "../../../service";
// import React from 'react'

// interface FormilyImageProps extends ImageProps, IClickEventProps {
//   noPreview?: boolean
// }

// const PreviewImage: ReactFC<FormilyImageProps> = ({clickEventProps = {}, ...props}) => {
//   let clickProps = {};
//   if(clickEventProps) {
//     clickProps = clickEventToProps(clickEventProps);
//   }
//   return (
//     <AntdImage
//       {...props}
//     />
//   )
// }

export const Image: ReactFC<ImageProps> = connect(
  AntdImage,
)

export default Image
