import React from 'react'
import { CheckList as AntdCheckList } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import { Field, useField } from '@formily/react';

export const CheckList: DnFC<React.ComponentProps<typeof AntdCheckList>> = (props) => { 
  const field: any = useField();
  const dataSource = props.options || [];
  field.setDataSource(dataSource)
  return <AntdCheckList {...props} />
}

CheckList.Behavior = createBehavior({
  name: 'CheckList',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'CheckList',
  designerProps: {
    propsSchema: createFieldSchema(AllSchemas.CheckList),
  },
  designerLocales: {
    'zh-CN': {
      title: '可勾选列表',
      settings: {
        'x-component-props': {
          multiple: '是否多选',
        },
      },
    }
  },
})

CheckList.Resource = createResource({
  icon: 'CheckListSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'boolean',
        title: 'CheckList',
        'x-decorator': 'FormItem',
        'x-component': 'CheckList',
        'x-component-props': {
          options: [
            { label: '选项1', value: '1' },
            { label: '选项2', value: '2' },
          ],
        }
      },
    },
  ],
})
