import React from 'react'
import { createBehavior, createResource } from '@designable/core'
import { DnFC, DroppableWidget } from '@designable/react'
import { createBaseSchema } from '../Field'
import FormilyBox from './Box'

export const Box: DnFC<any> = FormilyBox

Box.Behavior = createBehavior({
  name: 'Box',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Box',
  designerProps: {
    droppable: true,
    propsSchema: createBaseSchema(),
  },
  designerLocales: {
    'zh-CN': {
      title: '普通容器',
    }
  },
})

Box.Resource = createResource({
  icon: 'BoxSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'Box',
      },
    },
  ],
})
