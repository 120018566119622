import React, { useEffect, useMemo, useState } from 'react'
import { clone } from '@formily/shared'
import { createForm, onFieldValueChange, onFieldMount } from '@formily/core'
import { createSchemaField, useExpressionScope } from '@formily/react'
import { GlobalRegistry } from '@designable/core'
import { requestIdle, uid } from '@designable/shared'
import { usePrefix } from '@designable/react'
import {
  Form,
  ArrayTable,
  Input,
  Select,
  FormItem,
  Switch
} from '@formily/antd'
import { Button, Spin } from 'antd'
//@ts-ignore
import Modal from "@/components/Modal/Modal";
import { IReaction } from '@designable/formily-setters/lib/components/ReactionsSetter/types'
import { IconSelect } from '../index'
export interface IStepSetterProps {
  value?: IReaction & { columns: any[] }
  onChange?: (value: IReaction) => void
}

const SchemaField = createSchemaField({
  components: {
    Input,
    Select,
    FormItem,
    ArrayTable,
    Switch,
  },
})



export const StepSetter: React.FC<IStepSetterProps> = (props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [innerVisible, setInnerVisible] = useState(false)
  const $scope = useExpressionScope();
  const prefix = usePrefix('table-setter')
  const form = useMemo(() => {
    return createForm({
      values: {steps: clone(props.value)},
    })
  }, [modalVisible, props.value])
  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)
  useEffect(() => {
    if (modalVisible) {
      requestIdle(
        () => {
          setInnerVisible(true)
        },
        {
          timeout: 4000,
        }
      )
    } else {
      setInnerVisible(false)
    }
  }, [modalVisible])
  return (
    <>
      <Button block onClick={openModal}>
        配置数据
      </Button>
      <Modal
        title="配置数据"
        width="60%"
        style={{ top: '10vh' }}
        bodyStyle={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(80vh - 100px)",
          minHeight: 320,
          background: "#fff",
          padding: "16px 24px",
        }}
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={async() => {
          form.validate().then(() => {
            form.submit((values) => {
              props.onChange?.(values.steps || [])
            })
            closeModal()
          })
        }}
      >
        <div className={prefix}>
          {innerVisible ? (
            <Form form={form}>
              <SchemaField scope={{...$scope}}>
                <SchemaField.Array
                  name="steps"
                  default={[{}]}
                  x-component="ArrayTable"
                  x-component-props={{
                    scroll: {
                      x: 'max-content',
                      y: "calc(80vh - 255px)"
                    }
                  }}
                >
                  <SchemaField.Object>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '标题',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="title"
                        x-decorator="FormItem"
                        x-component="Input"
                        required
                        x-component-props={{
                          placeholder: '请输入'
                        }}
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '唯一标识',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="key"
                        x-decorator="FormItem"
                        required
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '描述',
                        width: 120,
                        fixed: 'left'
                      }}
                    >
                      <SchemaField.String
                        name="description"
                        x-decorator="FormItem"
                        x-component="Input"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '状态',
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="status"
                        x-decorator="FormItem"
                        enum={[
                          {
                            label: '未开始',
                            value: 'wait'
                          },
                          {
                            label: '进行中',
                            value: 'process'
                          },
                          {
                            label: '已完成',
                            value: 'finish'
                          },
                          {
                            label: '已失败',
                            value: 'error'
                          }
                        ]}
                        x-component="Select"
                      />
                    </SchemaField.Void>
                    <SchemaField.Void
                      x-component="ArrayTable.Column"
                      x-component-props={{
                        title: '图标',
                        width: 120,
                      }}
                    >
                      <SchemaField.String
                        name="iconName"
                        x-decorator="FormItem"
                        x-component={IconSelect}
                      />
                    </SchemaField.Void>
                  </SchemaField.Object>
                  <SchemaField.Void
                    title={GlobalRegistry.getDesignerMessage(
                      'SettingComponents.ReactionsSetter.addRelationField'
                    )}
                    x-component="ArrayTable.Addition"
                    x-component-props={{ style: { marginTop: 8 } }}
                  />
                </SchemaField.Array>
              </SchemaField>
            </Form>
          ) : (<div className="example">
                <Spin />
              </div>)
      }
        </div>
      </Modal>
    </>
  )
}
