import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Tag as AntdTag, TagProps } from 'antd-mobile'
import React from 'react'

export interface FormilyTagProps extends TagProps {}
const FormilyTag: ReactFC<FormilyTagProps> = connect(
  AntdTag,
)

export default FormilyTag

export const MobileTag = ({title, customColor, ...props}) => {
  props.color = props.color || customColor;
  return <AntdTag {...props}>{ title }</AntdTag>
}
