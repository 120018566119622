import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Steps, StepsProps } from 'antd-mobile'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { uid } from '@formily/shared'

export interface FormilyStepsProps extends StepsProps {
  steps: any[]
}
export const FormilySteps = ({steps, ...props}: FormilyStepsProps) => {
  return (
    <Steps {...props}>
      {steps?.map((item) => (
        <Steps.Step
          key={uid() || item.key}
          title={item.title}
          description={item.description}
          status={item.status}
          icon={item.iconName ? <Icon name={item.iconName} /> : null}
        />
      ))}
    </Steps>
  );
}

export const AntdSteps: ReactFC<FormilyStepsProps> = connect(
  FormilySteps,
)

export default AntdSteps
