export const Grid = {
  'zh-CN': {
    title: '多列布局',
    addGridColumn: '添加网格列',
    settings: {
      'x-component-props': {
        columns: '列数',
        rowMargin: '行间距',
        columnMargin: '列间距',
      },
    },
  },
  'en-US': {
    title: 'Grid',
    addGridColumn: 'Add Grid Column',
    settings: {
      'x-component-props': {
        minWidth: 'Min Width',
        minColumns: 'Min Columns',
        maxWidth: 'Max Width',
        maxColumns: 'Max Columns',
        breakpoints: 'Breakpoints',
        columnGap: 'Column Gap',
        rowGap: 'Row Gap',
        colWrap: 'Col Wrap',
      },
    },
  },
  'ko-KR': {
    title: '그리드 열',
    addGridColumn: '그리드 열 추가',
    settings: {
      'x-component-props': {
        minWidth: '최소 너비',
        minColumns: '최소 열 개수',
        maxWidth: '최대 너비',
        maxColumns: '최대 열 개수',
        breakpoints: '중단점',
        columnGap: '열 간격',
        rowGap: '행 간격',
        colWrap: '자동 줄바꿈',
      },
    },
  },
}

export const GridColumn = {
  'zh-CN': {
    title: '网格列',
    settings: {
      'x-component-props': {
        gridSpan: '跨列栏数',
      },
    },
  },
  'en-US': {
    title: 'Grid Column',
    settings: {
      'x-component-props': {
        gridSpan: 'Grid Span',
      },
    },
  },
  'ko-KR': {
    title: '그리드 열',
    settings: {
      'x-component-props': {
        gridSpan: '그리드 스팬',
      },
    },
  },
}
