import { ISchema } from '@formily/react'

export const Tag: ISchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      "x-component-props": {
        placeholder: '请输入',
        allowClear: true
      }
    },
    color: {
      type: 'string',
      'x-decorator': 'FormItem',
      enum: ['default', 'primary', 'success', 'warning', 'danger', 'magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'],
      'x-component': 'Select',
      "x-component-props": {
        placeholder: '请选择',
        allowClear: true
      }
    },
    customColor: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'ColorInput',
      "x-component-props": {
        allowClear: true
      }
    },
    fill: {
      title: '填充模式',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Radio.Group',
      'x-component-props': {
        optionType: 'button',
        buttonStyle: 'solid',
        defaultValue: 'solid',
        options: [
          {
            label: '填充',
            value: 'solid'
          },
          {
            label: '描边',
            value: 'outline'
          }
        ]
      }
    },
    round: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
  },
}
