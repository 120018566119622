import { ISchema } from '@formily/react'
import { IconSelect } from '../setters';

export const Icon: ISchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": IconSelect,
    },
    // rotate: {
    //   type: "number",
    //   "x-decorator": "FormItem",
    //   "x-component": "NumberPicker",
    // },
    // spin: {
    //   type: "boolean",
    //   "x-decorator": "FormItem",
    //   "x-component": "Switch",
    // },
  },
};
