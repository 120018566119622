import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Menu as AntdMenu } from 'antd'
import type { MenuProps } from 'antd';

export interface FormilyMenuProps extends MenuProps {
  noPreview?: boolean
  items: any[];
}
const FormilyMenu: ReactFC<FormilyMenuProps> = connect(
  AntdMenu,
)

export default FormilyMenu
