import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Swiper, SwiperProps, Image, Toast } from 'antd-mobile'
import React from 'react'
import './styles.less'
import { clickEventToProps } from '../../../service/utils'

interface FormilyItemProps {
  key?: string
  src?: string
  clickEventProps?: any
}
export interface FormilySwiperProps extends SwiperProps {
  items?: FormilyItemProps[]
  height?: any
}
const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']
const FormilySwiper = ({items, height, style, ...props}: FormilySwiperProps) => {
  return <Swiper {...props} style={{ ...style, '--height': height}} >
    {items?.map((item, index) => {
      let clickProps;
      if (item.clickEventProps) {
        clickProps = clickEventToProps(item.clickEventProps);
      }
      return (<Swiper.Item key={item.key} >
        {item.src ? <Image height={height} src={item.src} />
          : 
          <div
            className='swiper-placeholder'
            style={{ backgroundColor: colors[index%4] }}
            {...clickProps}
          >
            {index + 1}
          </div>
        }
      </Swiper.Item>)
    }
      
    )}
  </Swiper>
}

export const AntdSwiper: ReactFC<FormilySwiperProps> = connect(
  FormilySwiper,
)

export default AntdSwiper
