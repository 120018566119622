import React from 'react'
import { Checkbox as AntdCheckbox } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import {
  DataSourceSetter,
} from '@designable/formily-setters'

export const Checkbox: DnFC<React.ComponentProps<typeof AntdCheckbox>> = AntdCheckbox

Checkbox.Behavior = createBehavior({
  name: 'Checkbox',
  extends: ['Field'],
  selector: (node) => node.props['x-component'] === 'Checkbox',
  designerProps: {
    propsSchema: createFieldSchema({
      type: 'object',
      properties: {
        options: {
          title:'配置可选项',
          "x-decorator": 'FormItem',
          "x-component": DataSourceSetter
        },
      },
    }),
  },
  designerLocales: {
    'zh-CN': {
      title: '复选框',
    }
  },
})

Checkbox.Resource = createResource({
  icon: 'CheckboxGroupSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'boolean',
        title: 'Checkbox',
        'x-decorator': 'FormItem',
        'x-component': 'Checkbox',
        'x-component-props': {
          options: [
            { label: '选项1', value: '1' },
            { label: '选项2', value: '2' },
          ],
        }
      },
    },
  ],
})
