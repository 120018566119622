import { Field, useField } from '@formily/react';
import React from 'react'
import { CascadePicker as FormilyCascadePicker } from '@formily/antd-mobile'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createFieldSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import { removeEmptyChildren } from '../../../service';

export const CascadePicker: DnFC<React.ComponentProps<typeof FormilyCascadePicker>> = (props) => {
  const field: any = useField();
  // const dataSource = field.dataSource || [];
  // field.setDataSource(removeEmptyChildren(dataSource))
  return React.createElement(FormilyCascadePicker, props)
}
  

CascadePicker.Behavior = createBehavior(
  {
    name: 'CascadePicker',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'CascadePicker',
    designerProps: {
      propsSchema: createFieldSchema(AllSchemas.CascadePicker),
    },
    designerLocales: AllLocales.CascadePicker,
  },
)

CascadePicker.Resource = createResource(
  {
    icon: 'CascadePickerSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'string',
          title: 'CascadePicker',
          'x-decorator': 'FormItem',
          'x-component': 'CascadePicker',
          'x-component-props': {
            placeholder: '请选择',
            options: [
              {
                label: '选项1',
                value: 'key1'
              },
              {
                label: '选项2',
                value: 'key2'
              }
            ]
          },
        },
      },
    ],
  },
)
