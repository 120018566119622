export const Selector = {
  'zh-CN': {
    title: '选择组',
    settings: {
      'x-component-props': {
        columns: '列数',
        multiple: '是否多选',
        disabled: '禁止',
      },
    },
  },
}
