import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { List, ListProps, Image, SwipeAction } from 'antd-mobile'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { clickEventToProps } from '../../../service/utils'

export interface FormilyListProps extends ListProps {
  items: any[]
  action?: boolean
  leftActions?: any[]
  rightActions?: any[]
}

export const PreviewList = ({ items = [], action = false, leftActions = [], rightActions = [],  ...props}: FormilyListProps) => {
  
  return (<List {...props}>
    {
      items.map((item, index) => {
        const { name, prefixType, prefixIconName, prefixSrc, clickEventProps, ...itemProps } = item;
        let clickProps;
        if (clickEventProps) {
          clickProps = clickEventToProps(clickEventProps);
        }
        if (!action) {
          return (
            <List.Item
              prefix={
                prefixType == 'image' ? 
                <Image
                  src={prefixSrc || ''}
                  style={{ borderRadius: 20 }}
                  fit="cover"
                  width={40}
                  height={40}
                />
                :
                (prefixIconName ? <Icon style={{ fontSize: '20px'}} name={prefixIconName} /> : null)
              }
              key={index}
              {...itemProps}
              {...clickProps}
            >
              {item.name}
            </List.Item>
          );
        } else {
          return (
            <SwipeAction
              key={itemProps.key || index}
              leftActions={leftActions}
              rightActions={rightActions}
            >
              <List.Item
                prefix={
                  prefixType == "image" ? (
                    <Image
                      src={prefixSrc || ""}
                      style={{ borderRadius: 20 }}
                      fit="cover"
                      width={40}
                      height={40}
                    />
                  ) : prefixIconName ? (
                    <Icon style={{ fontSize: "20px" }} name={prefixIconName} />
                  ) : null
                }
                {...itemProps}
                {...clickProps}
              >
                {item.name}
              </List.Item>
            </SwipeAction>
          );
        }
        
      })
    }
  </List>)
}

const FormilyList = ({ items = [], action = false, leftActions = [], rightActions = [],  ...props}: FormilyListProps) => {
  return (<List {...props}>
    {
      items.map((item, index) => {
        const { name, prefixType, prefixIconName, prefixSrc, ...itemProps } = item;
        return (
          <List.Item
            prefix={
              prefixType == 'image' ? 
              <Image
                src={prefixSrc || ''}
                style={{ borderRadius: 20 }}
                fit="cover"
                width={40}
                height={40}
              />
              :
              (prefixIconName ? <Icon style={{ fontSize: '20px'}} name={prefixIconName} /> : null)
            }
            key={index}
            {...itemProps}
          >
            {item.name}
          </List.Item>
        );
      })
    }
  </List>)
}

export const AntdList: ReactFC<FormilyListProps> = connect(
  FormilyList,
)

export default AntdList
