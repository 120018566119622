import { ISchema } from '@formily/react'
import { ListSetter, ActionSetter } from '../setters';


export const List: ISchema = {
  type: "object",
  properties: {
    items: {
      type: "array",
      "x-decorator": "FormItem",
      "x-component": ListSetter
    },
    header: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Input",
      "x-component-props": {
        defaultValue: '',
        placeholder: '请输入'
      },
    },
    mode: {
      type: 'string',
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      enum: [
        {
          label: '默认',
          value: 'default',
        },
        {
          label: '卡片',
          value: 'card',
        },
      ],
      "x-component-props": {
        optionType: 'button',
        defaultValue: 'default',
        buttonStyle: 'solid',
        size: 'small',
      },
    },
    action: {
      type: 'boolean',
      "x-decorator": "FormItem",
      "x-component": "Switch",
      title: '支持滑动操作',
    },
    leftActions: {
      title: '左侧操作',
      type: 'array',
      "x-decorator": "FormItem",
      "x-component": ActionSetter,
      "x-reactions": {
        dependencies: ['.action'],
        fulfill: {
          state: {
            visible: '{{!!$deps[0]}}'
          }
        }
      }
    },
    rightActions: {
      title: '右侧操作',
      type: 'array',
      "x-decorator": "FormItem",
      "x-component": ActionSetter,
      "x-reactions": {
        dependencies: ['.action'],
        fulfill: {
          state: {
            visible: '{{!!$deps[0]}}'
          }
        }
      }
    }
  },
};

