import { ISchema } from '@formily/react'
import {
  DataSourceSetter,
} from '@designable/formily-setters'

export const Checkbox: ISchema & { Group?: ISchema } = {
  type: 'object',
  properties: {
    // autoFocus: {
    //   type: 'boolean',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'Switch',
    // },
  },
}

Checkbox.Group = {
  type: 'object',
  properties: {
    options: {
      "x-decorator": 'FormItem',
      "x-component": DataSourceSetter
    },
  }
}