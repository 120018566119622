import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { TabBar, TabBarProps, TabBarItemProps } from 'antd-mobile'
import React from 'react'
import { Icon } from '../Icon/Icon'
import { clickEventToProps } from '../../../service/utils'

interface FormilyItemProps  extends TabBarItemProps {
  key?: string
  iconName: string
}
export interface FormilyTabBarProps extends TabBarProps {
  tabs?: FormilyItemProps[]
}
const FormilyTabBar = ({tabs, ...props}: FormilyTabBarProps) => {
  return <TabBar {...props} >
    {tabs?.map((item) => <TabBar.Item key={item.key} title={item.title} badge={item.badge} icon={<Icon name={item.iconName} />}/>)}
  </TabBar>
}

export const PreviewTabBar = ({tabs, ...props}: FormilyTabBarProps) => {
  const handleChange = (key) => {
    const result = tabs.filter(item => item.key === key);
    const clickEventProps = result[0]['clickEventProps'];
    let changeEvent = clickEventToProps(clickEventProps)
    if (changeEvent.onClick) changeEvent.onClick()
  }
  return <TabBar {...props} onChange={handleChange} >
    {tabs?.map((item) => <TabBar.Item key={item.key} title={item.title} badge={item.badge} icon={<Icon name={item.iconName} />}/>)}
  </TabBar>
}

export const AntdTabBar: ReactFC<FormilyTabBarProps> = connect(
  FormilyTabBar,
)

export default AntdTabBar
