import React from "react";
import { useField, useForm, connect } from "@formily/react";
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { Avatar as AntdAvatar, AvatarProps } from "antd-mobile";

export const Avatar: DnFC<AvatarProps> = connect(AntdAvatar);

Avatar.Behavior = createBehavior({
  name: "Avatar",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Avatar",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Avatar),
  },
  designerLocales: AllLocales.Avatar,
});

Avatar.Resource = createResource({
  icon: "AvatarSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Avatar",
        "x-component-props": {
          src: ''
        }
      },
    },
  ],
});