export default {
  'zh-CN': {
    customSettingComponents: {
      TableSetter: {
        title: '标题',
        dataIndex: '标题标识',
        actionId: '唯一标识',
        actionType: '操作类型',
        actionTitle: '操作文本',
        actionModalId: '详情',
        actionProps: "参数（不填默认整行数据）"
      }
    }
  }
}