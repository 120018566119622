import React from "react";
import FormilyAlert, { FormilyAlertProps } from "./Alert"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";

export const Alert: DnFC<FormilyAlertProps> = ({
  ...props
}) => {
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-alert"
    >
      <FormilyAlert {...props} ></FormilyAlert>
    </div>
  );
};

Alert.Behavior = createBehavior({
  name: "Alert",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Alert",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Alert),
  },
  designerLocales: AllLocales.Alert,
});

Alert.Resource = createResource({
  icon: "AlertSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Alert",
        "x-component-props": {
          type: "success",
          message: "This is a prompt message for success",
        },
      },
    },
  ],
});