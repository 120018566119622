export const Icon = {
  'zh-CN': {
    title: '图标',
    settings: {
      'x-component-props': {
        name: '图标选择',
        rotate: '旋转角度',
        spin: '是否有旋转动画'
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
}
