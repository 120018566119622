import { ISchema } from '@formily/react'

export const Slider: ISchema = {
  type: 'object',
  properties: {
    // ticks: {
    //   type: 'boolean',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'Switch',
    // },
    max: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      "x-component-props": {
        defaultValue: 100
      }
    },
    min: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      "x-component-props": {
        defaultValue: 0
      }
    },
    // range: {
    //   type: 'string',
    //   'x-decorator': 'FormItem',
    //   'x-component': 'Switch',
    // },
    step: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'NumberPicker',
      "x-decorator-props": {
        tooltip: '步距，取值必须大于 0，并且 (max - min) 可被 step 整除。'
      },
      "x-component-props": {
        min: 1
      }
    }
  },
}

