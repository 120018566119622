export const Tag = {
  'zh-CN': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: {title: '预设颜色', dataSource: [
          '默认',     // default
          '主要',     // primary
          '成功',     // success
          '警告',     // warning
          '危险',     // danger
          '品红',     // magenta
          '红色',     // red
          '火山红',   // volcano
          '橙色',     // orange
          '金色',     // gold
          '酸橙绿',   // lime
          '绿色',     // green
          '青色',     // cyan
          '蓝色',     // blue
          '极客蓝',   // geekblue
          '紫色'      // purple
        ]},
        customColor: '自定义颜色',
        round: '是否圆角',
        iconName: '图标'
      },
    },
  },
}
