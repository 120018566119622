import { connect } from "@formily/react";
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createVoidFieldSchema } from "../Field";
import { ImageUploader as AntdImageUploader, ImageUploaderProps } from "antd-mobile";


export const ImageUploader: DnFC<ImageUploaderProps> = connect(AntdImageUploader);

ImageUploader.Behavior = createBehavior({
  name: "ImageUploader",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "ImageUploader",
  designerProps: {
    propsSchema: createVoidFieldSchema(),
  },
  designerLocales: {
    'zh-CN': {
      title: '图片上传',
      settings: {
        'x-component-props': {
          src: '头像的图片地址',
          fit: "图片填充模式",
        },
      },
    },
  },
});

ImageUploader.Resource = createResource({
  icon: "ImageUploaderSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: 'string',
        title: '图片上传',
        'x-decorator': 'FormItem',
        "x-component": "ImageUploader",
        "x-component-props": {
          src: ''
        }
      },
    },
  ],
});