import { ISchema } from '@formily/react'

export const Affix: ISchema = {
  type: "object",
  properties: {
    type: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "Radio.Group",
      "x-decorator-props": {
        tooltip: '配置页面不生效'
      },
      "x-component-props": {
        defaultValue: "top",
        optionType: "button",
        buttonStyle: "solid",
        options: [
          {
            label: "顶部",
            value: "top",
          },
          {
           label: "底部",
           value: 'bottom'
          }
        ]
      }
    },
    offsetTop: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      description: '0代表不吸附',
      "x-reactions": [
        {
          dependencies: [".type"],
          fulfill: {
            state: {
              hidden: '{{$deps[0] == "bottom"}}',
            },
          },
        },
      ],
    },
    offsetBottom: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      description: '可以为0',
      "x-reactions": [
        {
          dependencies: [".type"],
          fulfill: {
            state: {
              visible: '{{$deps[0] == "bottom"}}',
            },
          },
        },
      ],
    },
  },
};
