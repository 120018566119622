import { Switch as AntdSwitch } from 'antd'
import { connect, mapProps } from '@formily/react'

export const Switch = connect(
  AntdSwitch,
  mapProps(
    {
      value: 'checked',
    },
    (props) => {
      delete props['value']
      props.checked = Boolean(props.checked)
      return {
        ...props,
        style: {
            position: 'relative',
            top: '-2px',
            ...props.style
        }
      }
    }
  )
)

export default Switch