import React from 'react'
import { TreeNode, createBehavior, createResource, SelectNodeEvent } from '@designable/core'
import {
  DnFC,
  useTreeNode,
  useNodeIdProps,
  DroppableWidget,
  useDesigner
} from '@designable/react'
import { observer } from '@formily/reactive-react'
import { LoadTemplate } from '../../common/LoadTemplate'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import './styles.less'
import { uid } from '@formily/shared'
import { useEffect } from 'react'
import { useState } from 'react'
import classnames from 'classnames'
import Background from 'components/UiDesigner/SidePanel/DesignPane/Background'

// type formilyGrid = typeof FormilyGird
interface IdesignableGridProps {
  columns: any
  rowMargin: any
  columnMargin: any
  RowGridColumn: any
  style: any
  children: any
}

export const RowGrid: DnFC<IdesignableGridProps> & {
  RowGridColumn?: React.FC<any>
} = observer(({ columns, rowMargin, columnMargin, ...props }) => {
  const node = useTreeNode()
  const nodeId = useNodeIdProps()
  const engine = useDesigner()
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    return engine.subscribeTo(SelectNodeEvent, (event) => {
      setSelected(false)
      const source = event.data?.source;
      // @ts-ignore
      if (source && source?.length > 0) {
        if (source[0].id === node.id) {
          setSelected(true)
        }
      }
    })
  }, [])
  const gridStyle = {};
    gridStyle['gridTemplateRows'] = columns || undefined;
    gridStyle['gridRowGap'] = rowMargin;
    gridStyle['gridColumnGap'] = columnMargin;
  if (node.children.length === 0) { 
    return (
      <div className="dn-row-layout-grid" {...props}>
        <DroppableWidget />
        <LoadTemplate
          actions={[
            {
              title: node.getMessage('addGridColumn'),
              icon: 'AddColumn',
              onClick: () => {
                const column = new TreeNode({
                  componentName: 'Field',
                  props: {
                    type: 'void',
                    'x-component': 'RowGrid.RowGridColumn',
                  },
                })
                node.append(column)
              },
            },
          ]}
        />
      </div>
    )

  }

  return (
    <div {...nodeId}  className={classnames("dn-row-layout-grid", {'dn-row-grid-selected': selected})} >
      <div {...props} 
        className='dn-row-grid'
        key={uid()}
        style={{
            ...props.style,
            ...gridStyle,
            display: "grid",
          }}>{props.children}</div>
      <div className='dn-grid-load-action'>
      <LoadTemplate
        actions={[
          {
            title: node.getMessage('addGridColumn'),
            icon: 'AddColumn',
            onClick: () => {
              const column = new TreeNode({
                componentName: 'Field',
                props: {
                  type: 'void',
                  'x-component': 'RowGrid.RowGridColumn',
                },
              })
              node.append(column)
            },
          },
        ]}
      />
      </div>
    </div>
  )
})

RowGrid.RowGridColumn = observer(({ children, ...props }) => {
  return (
    <DroppableWidget {...props} className='grid-layout-item'>
      {children}
    </DroppableWidget>
  )
})

RowGrid.Behavior = createBehavior(
  {
    name: 'RowGrid',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'RowGrid',
    designerProps: {
      droppable: true,
      allowDrop: (node) => node.props['x-component'] !== 'RowGrid',
      propsSchema: createBaseSchema(AllSchemas.RowGrid),
    },
    designerLocales: AllLocales.RowGrid,
  },
  {
    name: 'RowGrid.RowGridColumn',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'RowGrid.RowGridColumn',
    designerProps: {
      droppable: true,
      resizeXPath: 'x-component-props.gridSpan',
      resizeStep: 1,
      resizeMin: 1,
      resizeMax: 12,
      allowDrop: (node) => node.props['x-component'] === 'RowGrid',
      propsSchema: createBaseSchema(),
    },
    designerLocales: AllLocales.RowGridColumn,
  }
)

RowGrid.Resource = createResource({
  icon: 'LayoutGridSource',
  elements: [
    {
      componentName: 'Field',
      props: {
        type: 'void',
        'x-component': 'RowGrid',
        'x-component-props': {
          columns: '45px auto 50px',
          rowMargin: '10px',
          columnMargin: '10px'
        }
      },
      children: [
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'RowGrid.RowGridColumn',
            "x-component-props": {
              style: {
                overflowY: 'hidden',
              }
            }
          },
        },
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'RowGrid.RowGridColumn',
            "x-component-props": {
              style: {
                overflowY: 'auto',
              }
            }
          },
        },
        {
          componentName: 'Field',
          props: {
            type: 'void',
            'x-component': 'RowGrid.RowGridColumn',
            "x-component-props": {
              style: {
                overflowY: 'hidden',
              }
            }
          },
        },
      ],
    },
  ],
})


export const createRowGrid = ({columns, rowMargin, columnMargin, children, ...props}) => {
  const gridStyle = {};
    gridStyle['gridTemplateRows'] = columns || undefined;
    gridStyle['gridRowGap'] = rowMargin;
    gridStyle['gridColumnGap'] = columnMargin;
  return <div className="dn-row-layout-grid">
    <div {...props} 
      className='dn-row-grid-preview'
      key={uid()}
      style={{
          ...props.style,
          ...gridStyle,
          display: "grid",
        }}>
          { children }
    </div>
  </div>
}

const RowGridColumn = observer(({ children, ...props }) => {
  return (
    <div {...props} className='grid-layout-item'>{children}</div>
  )
})

export const LayoutRowGrid = Object.assign(createRowGrid, {
  RowGridColumn,
})