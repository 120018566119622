export const ObjectLocale = {
  'zh-CN': {
    title: '对象容器',
  },
  'en-US': {
    title: 'Object',
  },
  'ko-KR': {
    title: '오브젝트',
  },
}
