import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyImage, { FormilyImageProps } from "./Image";
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { ImageSource } from '@designable/react/src/icons'
import { clickEventToProps } from "../../../service";
import "./styles.less";

interface IdesignableImageProps extends FormilyImageProps {
  clickEventProps?: any

}

export const Image: DnFC<IdesignableImageProps> = ({
  noPreview,
  clickEventProps = {},
  style,
  ...props
}) => {
  const field = useField();
  const form: any = useForm();
  let editProps: any = {};
  if (
    props["data-designer-node-id"] ||
    field.decoratorProps["data-designer-node-id"] ||
    noPreview
  ) {
    editProps.preview = false;
  }
  let clickProps = {};
  // if (actionType == 'buttonSetterSearch') {
  //   delete props.href;
  //   props.onClick = () => form.notify('search-btn', {searchArr})
  // }
  if (!props['data-designer-node-id'] && !field.decoratorProps['data-designer-node-id']) {
    clickProps = clickEventToProps(clickEventProps, form);
  }
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-image"
      style={props.src ? {overflow: 'hidden', ...style} : {
        height: '100px',
        width: '100%' 
      }}
    >
      {!props.src ? <svg
        viewBox="0 0 1024 1024"
        height={100}
        width={100}
        fill="currentColor"
        focusable="false"
        aria-hidden="true"
      >
        {ImageSource}
      </svg> : 
      <FormilyImage width={'100%'} height={'100%'} {...props} {...clickProps} {...editProps} />
      }
    </div>
  );
};

Image.Behavior = createBehavior({
  name: "Image",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Image",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Image),
  },
  designerLocales: AllLocales.Image,
});

Image.Resource = createResource({
  icon: "ImageSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Image",
      },
    },
  ],
});