export const CascadePicker = {
  'zh-CN': {
    title: '级联选择',
    settings: {
      'x-component-props': {
        
      },
    },
  },
}
