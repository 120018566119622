import React from "react";
import FormilyImage from "./Image";
import type { ImageProps } from 'antd-mobile/es'
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import classnames from 'classnames'
import "./styles.less";

export const Image: DnFC<ImageProps> = ({
  ...props
}) => {
  
  return (
    <FormilyImage className={classnames({"dm-image": !props.src})} src='/404' {...props} />
  );
};

Image.Behavior = createBehavior({
  name: "Image",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Image",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Image),
  },
  designerLocales: AllLocales.Image,
});

Image.Resource = createResource({
  icon: "ImageSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "string",
        "x-component": "Image",
      },
    },
  ],
});