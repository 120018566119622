import React from "react";
import { useField, useForm } from "@formily/react";
import FormilyIcon, { FormilyIconProps } from "./Icon"
import { createBehavior, createResource } from "@designable/core";
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import "./styles.less";

export const Icon: DnFC<FormilyIconProps> = ({
  ...props
}) => {
  return (
    <div
      data-designer-node-id={props["data-designer-node-id"]}
      className="dn-formily-icon"
    >
      {props.name ? <FormilyIcon {...props} ></FormilyIcon> : <span className="dm-formily-icon-empty"></span>  }
    </div>
  );
};

Icon.Behavior = createBehavior({
  name: "Icon",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Icon",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Icon),
  },
  designerLocales: AllLocales.Icon,
});

Icon.Resource = createResource({
  icon: "IconSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Icon",
        "x-component-props": {
          
        },
      },
    },
  ],
});