export const FormTab = {
  'zh-CN': {
    title: 'Tabs选项卡',
    addTabPane: '添加选项卡',
    settings: {
      'x-component-props': {
        animated: '启用动画过渡',
        centered: '标签居中',
        tab: '选项名称',
        direction: {
          title: '有无选中线',
          dataSource: [
            { label: '有', value: 'ltr' },
            { label: '无', value: 'rtl' },
          ],
        },
      },
    },
  },
  'en-US': {
    title: 'Tabs',
    addTabPane: 'Add Panel',
    settings: {
      'x-component-props': {
        animated: 'Enable Animated',
        centered: 'Label Centered',
        tab: 'Tab Title',
        type: {
          title: 'Type',
          dataSource: [
            { label: 'Line', value: 'line' },
            { label: 'Card', value: 'card' },
          ],
        },
      },
    },
  },
  'ko-KR': {
    title: '탭',
    addTabPane: '패널 추가',
    settings: {
      'x-component-props': {
        animated: '애니메이션 활성화',
        centered: '레이블을 가운데로',
        tab: '텝 제목',
        type: {
          title: '타입',
          dataSource: [
            { label: '라인', value: 'line' },
            { label: '카드', value: 'card' },
          ],
        },
      },
    },
  },
}

export const FormTabPane = {
  'zh-CN': {
    title: '选项卡面板',
    settings: {
      'x-component-props': {
        tab: '面板标题',
      },
    },
  },
  'en-US': {
    title: 'Tab Panel',
    settings: {
      'x-component-props': {
        tab: 'Panel Title',
      },
    },
  },
  'ko-KR': {
    title: '탭 패널',
    settings: {
      'x-component-props': {
        tab: '패널 제목',
      },
    },
  },
}
