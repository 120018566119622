export const SideBar = {
  'zh-CN': {
    title: '侧边栏',
    settings: {
      'x-component-props': {
        tabs: '数据',
        defaultActiveKey: '默认选中',
        children: '标题',
        title: '标题',
      },
    },
  },
}
