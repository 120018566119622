import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { Tree as AntdTree } from 'antd'
import type { TreeProps } from 'antd/es/Tree'
import React from 'react'

export interface FormilyTreeProps extends TreeProps {}
//@ts-ignore
const FormilyTree: ReactFC<FormilyTreeProps> = connect(
  AntdTree,
  mapProps({
    value: 'checkedKeys',
  }),
  mapReadPretty((props) => {
    return <AntdTree {...props} disabled />
  })
)

export default FormilyTree
