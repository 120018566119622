import React from "react";
import { useField, useForm, connect } from "@formily/react";
import { createBehavior, createResource } from "@designable/core";
import { AntdSideBar, FormilySideBarProps } from "./sideBar"; 
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";

export const SideBar: DnFC<FormilySideBarProps> =  (props) => {
  return (
    <AntdSideBar {...props} />
  );
}; 

SideBar.Behavior = createBehavior({
  name: "SideBar",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "SideBar",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.SideBar),
  },
  designerLocales: AllLocales.SideBar,
});

SideBar.Resource = createResource({
  icon: "SideBarSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "SideBar",
        "x-component-props": {
          tabs: [
            {
              key: 'side1',
              title: '未开始',
              badge: '1'
            },
            {
              key: 'side2',
              title: '进行中',
              badge: '2'
            },
            {
              key: 'side3',
              title: '已完成',
              badge: '3'
            },
          ]
        },
      },
    },
  ],
});