import { ISchema } from '@formily/react'
import { SideBarSetter } from '../setters'

export const SideBar: ISchema = {
  type: 'object',
  properties: {
    tabs: {
      type: 'array',
      'x-decorator': 'FormItem',
      'x-component': SideBarSetter,
    },
    defaultActiveKey: {
      title: '默认选中',
      type: 'string',
      "x-decorator": 'FormItem',
      "x-component": 'Select',
      enum: [],
      "x-component-props": {
        placeholder: '请选择',
        allowClear: true,
        fieldNames: {
          label: 'label',
          value: 'key',
        },
      },
      "x-reactions": (field) => {
        const items = field.query('.tabs').value()
        if (Array.isArray(items)) {
          const enums = items.map((item) => {
            return {
              label: item.title,
              key: item.key,
            }
          })
          field.setDataSource(enums)
        }
      }
    },
  },
}

