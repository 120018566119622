import { ISchema } from '@formily/react'
import { ClickEventSetter, IconSelect } from '../setters'

export const Tag: ISchema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      "x-component-props": {
        placeholder: '请输入',
        allowClear: true
      }
    },
    color: {
      type: 'string',
      'x-decorator': 'FormItem',
      enum: ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'],
      'x-component': 'Select',
      "x-component-props": {
        placeholder: '请选择',
        allowClear: true
      }
    },
    customColor: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'ColorInput',
      "x-component-props": {
        allowClear: true
      }
    },
    closable: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    iconName: {
      title: '图标',
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': IconSelect,
    },
  },
}
