import React, { useEffect, useMemo, useState } from 'react'
import { clone } from '@formily/shared'
import { GlobalRegistry } from '@designable/core'
import { CheckCircleOutlined } from '@ant-design/icons'
import { createSchemaField, useExpressionScope } from '@formily/react';
import { createForm, onFieldValueChange, onFieldMount } from '@formily/core'
import { requestIdle, uid } from '@designable/shared'
import {
  Form,
  ArrayTable,
  Input,
  Select,
  FormItem,
  Switch
} from '@formily/antd'
import { Modal, Button, Tag } from 'antd'
import colorSel from './colorSel';

const SchemaField = createSchemaField({
  components: {
    Input,
    Select,
    FormItem,
    ArrayTable,
    Switch,
  },
})

export const TagsSetter: React.FC<any> = (props: any) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [innerVisible, setInnerVisible] = useState(false)
  const { value, defaultValue } = props;
  const form = useMemo(() => {
    return createForm({
      values: { labels: clone(value) },
      effects() {
        // onFieldValueChange('columns', (field) => {
        //   form.setFieldState('dataSource', (state) => {
        //     //对于初始联动，如果字段找不到，setFieldState会将更新推入更新队列，直到字段出现再执行操作
        //     state.value = [];
        //   })
        // })
      }
    })
  }, [modalVisible, value])
  useEffect(() => {
    if (!value || value.length === 0) {
      props.onChange(defaultValue)
    }
  }, [])
  useEffect(() => {
    if (modalVisible) {
      requestIdle(
        () => {
          setInnerVisible(true)
        },
        {
          timeout: 4000,
        }
      )
    } else {
      setInnerVisible(false)
    }
  }, [modalVisible])
  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)
  return (
    <>
      <Button 
        block 
        //@ts-ignore
        icon={value && value.length > 0 ? <CheckCircleOutlined /> : ""}
        style={value && value.length > 0 ? {
          borderColor: '#52c41c',
          color: '#52c41c'
        } : {}}
        onClick={openModal}
      >
        配置数据
      </Button>
      <Modal
        title="配置数据"
        width={500}
        centered
        bodyStyle={{ padding: "16px 24px" }}
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={async () => {
          form.validate().then(() => {
            form.submit((values) => {
              props.onChange?.(values.labels);
            });
            closeModal();
          });
        }}
      >
        {innerVisible && (
          <Form form={form}>
            <SchemaField>
              <SchemaField.Array
                name="labels"
                default={[{}]}
                x-component="ArrayTable"
                x-component-props={{
                  onAdd: () => {
                    const cols = form.getValuesIn("labels");
                    if (!cols[cols.length - 1].dataIndex) {
                      cols[cols.length - 1].dataIndex = uid();
                    }
                    form.setValuesIn("labels", cols);
                  },
                }}
              >
                <SchemaField.Object>
                  <SchemaField.Void
                    x-component="ArrayTable.Column"
                    x-component-props={{
                      title: "字段",
                      width: 120,
                    }}
                  >
                    <SchemaField.String
                      name="value"
                      x-decorator="FormItem"
                      x-component="Input"
                      required
                      x-component-props={{
                        placeholder: "请输入",
                      }}
                    />
                  </SchemaField.Void>
                  <SchemaField.Void
                    x-component="ArrayTable.Column"
                    x-component-props={{
                      title: "标题",
                      width: 120,
                    }}
                  >
                    <SchemaField.String
                      name="text"
                      x-decorator="FormItem"
                      required
                      x-component="Input"
                      x-component-props={{
                        placeholder: "请输入",
                      }}
                    />
                  </SchemaField.Void>
                  <SchemaField.Void
                    x-component="ArrayTable.Column"
                    x-component-props={{
                      title: "颜色",
                      width: 120,
                    }}
                  >
                    <SchemaField.String
                      name="color"
                      x-decorator="FormItem"
                      required
                      x-component={colorSel}
                    />
                  </SchemaField.Void>
                  <SchemaField.Void
                    x-component="ArrayTable.Column"
                    x-component-props={{
                      title: GlobalRegistry.getDesignerMessage(
                        'SettingComponents.ReactionsSetter.operations'
                      ),
                      align: 'center',
                      width: 80,
                    }}
                  >
                    <SchemaField.Markup
                      type="void"
                      x-component="ArrayTable.Remove"
                    />
                  </SchemaField.Void>
                </SchemaField.Object>
                <SchemaField.Void
                  title="添加"
                  x-component="ArrayTable.Addition"
                  x-component-props={{ style: { marginTop: 8 } }}
                />
              </SchemaField.Array>
            </SchemaField>
          </Form>
        )}
      </Modal>
    </>
  );
}