import React from 'react'
import { connect, mapProps, mapReadPretty, ReactFC } from '@formily/react'
import { ProgressCircle, ProgressCircleProps } from 'antd-mobile'

export interface FormilyProgressCircleProps extends ProgressCircleProps {}
const PreviewProgressCircle: ReactFC<FormilyProgressCircleProps> = ({ percent, ...props}) => {
  return React.createElement(
    ProgressCircle,
    {
      percent: percent || 0,
      ...props,
    }
  )
}
const FormilyProgressCircle: ReactFC<FormilyProgressCircleProps> = connect(
  PreviewProgressCircle,
)

export default FormilyProgressCircle
