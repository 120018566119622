import React from 'react'
import { useForm, useField } from '@formily/react'
import FormilyButton, {  FormilyButtonProps } from './button'
import { createBehavior, createResource } from '@designable/core'
import { DnFC } from '@designable/react'
import { createBaseSchema } from '../Field'
import { AllSchemas } from '../../schemas'
import { AllLocales } from '../../locales'
import cls from 'classnames'
import { getOptions, clickEventToProps } from '../../../service';
import { clone } from '@designable/shared';
import { Icon } from "../../setters";
//@ts-ignore
import openWindow from '@/script/openWindow.mjs'
import './styles.less'
export interface IClickEventProps {
  clickEventProps: {
    actionType?: string
    modalDetail?: {
      title: string
      btnProps: any
      width: string,
      withProps: boolean
      modalId: string
    }
    openPage?: string
    href?: string
  }
}

export const Button: DnFC<FormilyButtonProps> = ({ clickEventProps = {}, ...props}) => {
  return (
    <FormilyButton
      {...props}
      className={cls(props.className, "dn-button")}
    />
  );
}


Button.Behavior = createBehavior(
  {
    name: 'Button',
    extends: ['Field'],
    selector: (node) => node.props['x-component'] === 'Button',
    designerProps: {
      propsSchema: createBaseSchema(AllSchemas.Button),
    },
    designerLocales: AllLocales.Button,
  },
)

Button.Resource = createResource(
  {
    icon: 'ButtonSource',
    elements: [
      {
        componentName: 'Field',
        props: {
          type: 'void',
          'x-component': 'Button',
          'x-component-props': {
            text: '按钮',
            size: 'middle',
            type: 'primary',
          }
        },
      },
    ],
  },
)