import { createBehavior, createResource } from "@designable/core";
import { AntdSwiper, FormilySwiperProps } from "./swiper"; 
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";

export const Swiper: DnFC<FormilySwiperProps> =  AntdSwiper;

Swiper.Behavior = createBehavior({
  name: "Swiper",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Swiper",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Swiper),
  },
  designerLocales: AllLocales.Swiper,
});

Swiper.Resource = createResource({
  icon: "SwiperSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Swiper",
        "x-component-props": {
          items: [
            {
              key: 'swiper1',
            },
            {
              key: 'swiper2',
            },
            {
              key: 'swiper3',
            },
          ]
        },
      },
    },
  ],
});