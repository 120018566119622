export const Steps = {
  'zh-CN': {
    title: '步骤条',
    settings: {
      'x-component-props': {
        items: '配置数据',
        current: '指定当前步骤',
        direction: '步骤条方向',
        type: '类型',
        labelPlacement: '标签放置位置',
        size: '大小',
        status: '状态',
        progressDot: '自定义进度点',
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: '颜色'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}

export const StepItem = {
  'zh-CN': {
    title: '步骤',
    settings: {
      'x-component-props': {
        title: '标题',
        subTitle: '子标题',
        description: '描述',
        icon: '图标',
        // status: '状态',
        disabled: '禁用',
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
      },
    },
  },
}
