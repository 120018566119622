import {Button, Col, Form, Input, Row, Tree, Typography} from 'antd'
import React, {useEffect, useState} from "react";
import Modal from '@/components/Modal/Modal'
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Table from "@/biz/Table";
import openWindow from "@/script/openWindow.mjs";
import StateData from "@/pages/MainPage/datas/StateData";
import {SearchOutlined} from "@ant-design/icons";
import {css} from "@emotion/react";

const cssForm = css({
    '.ant-form-item': {
        marginBottom: 0,
    }
})

/**
 * 选择界面视图弹窗(Formliy场景专用)
 * @param queryParams 需要传递给查询请求的参数
 * @param localData 本地数据，当存在本地数据优先使用本地数据，主要解决下拉框和本弹窗组合使用时，可能出现的数据源不一致问题
 * @param valueLabelProp 指定弹窗选择后返回的主键，默认为pkid，解决某些场景需要其他字段
 * @param value 默认选中项，当字段有值时，弹窗打开后会默认选中
 * @param onOk
 * @param multiple 多选模式，默认false
 * @param props
 * @returns {JSX.Element}
 */
export default function SelectUvByFormilyDialog({queryParams, valueLabelProp = 'pkid', value, onOk, multiple = false, ...props}) {
    const http = useHTTP();
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const rowSelection = {
        type: multiple ? 'checkbox' : 'radio',
        selectedRowKeys: selectedRows.map(item => item[valueLabelProp]),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            title: '界面代码',
            dataIndex: 'uiUserCode',
            width: 140,
            ellipsis: true,
            filter: <Table.FilterText/>,
            render: (text, record) => {
                return (
                    <a onClick={() => openWindow(`/UiDetail`, {uiId: record.uiId})}>
                        {text}
                    </a>
                )
            }
        },
        {
            title: '界面名称',
            dataIndex: 'uiName',
            width: 140,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '制品编号',
            dataIndex: 'dpSn',
            width: 100,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '界面视图代码',
            dataIndex: 'uvUserCode',
            width: 140,
            ellipsis: true,
            filter: <Table.FilterText/>,
            render: (text, record) => {
                return <a onClick={() => openWindow('/UvDetail', {uvId: record.pkid})}>{text}</a>;
            }
        },
        {
            title: '界面视图名称',
            dataIndex: 'uvName',
            width: 160,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '界面视图类型',
            dataIndex: 'uvType',
            width: 120,
            ellipsis: true,
            filter: <Table.FilterText getText={(text) => {
                return text == 'PC' ? 'PC端' : '移动端'
            }}/>,
            render: text => {
                return text == 'PC' ? 'PC端' : '移动端'
            }
        },
        {
            title: '页面类型',
            dataIndex: 'pageType',
            width: 100,
            ellipsis: true,
            filter: <Table.FilterText getText={(text) => {
                return text == 'PAGE' ? '页面' : '弹窗'
            }}/>,
            render: text => {
                return text == 'PAGE' ? '页面' : '弹窗'
            }
        },
        {
            title: '状态',
            dataIndex: 'uvStsCode',
            width: 80,
            filter: <Table.FilterText getText={(text) => {
                switch (text) {
                    case "DRAFT":
                        return '草稿';
                    case "REVISE":
                        return '修订';
                    case "RLS":
                        return '发布';
                    case "DISCARD":
                        return '废弃';
                    default:
                        return text;
                }
            }}/>,
            render: (text, record) => {
                return <StateData value={text}/>;
            }
        },
        {
            title: '发布人',
            dataIndex: 'rlsUserName',
            width: 100,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '发布时间',
            dataIndex: 'rlsTime',
            width: 110,
            ellipsis: true,
            filter: <Table.FilterDate/>,
        },
        {
            title: '更新人',
            dataIndex: 'uptName',
            width: 100,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '更新时间',
            dataIndex: 'uptTime',
            width: 110,
            ellipsis: true,
            filter: <Table.FilterDate/>,
        },
        {
            title: '创建人',
            dataIndex: 'crtName',
            width: 100,
            ellipsis: true,
            filter: <Table.FilterText/>,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right',
            render: (text, record) => {
                return (
                    <a onClick={() => handleTableRowClick(record)}>
                        选择
                    </a>
                )
            }
        },
    ]

    const doQuery = async () => {
        setLoading(true)
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/listUmUvByMap`, {stsCodes: ["RLS", "REVISE"], ...queryParams, ...form.getFieldsValue(),});
                }, {
                    error: {content: (err) => `查询界面视图失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                })
            if (data) {
                setData(data)
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const handleFormFinishClick = async () => {
        console.log(form.getFieldsValue())
        doQuery()
    }

    const handleFormResetClick = async () => {
        form.resetFields()
        doQuery()
    }

    const handleTableRowClick = (row) => {
        onOk([row])
    }

    const handleOk = async () => {
        onOk(selectedRows)
    }

    useEffect(() => {
        (async () => {
            await doQuery()

            if (queryParams) {
                form.setFieldsValue({...queryParams})
            }

            if (value) {
                setSelectedRows([value]);
            }
        })()
    }, [])

    return (
        <Modal
            title={"选择界面视图"}
            width={1280}
            style={{top: 20}}
            bodyStyle={{overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12, gap: 12}}
            onOk={handleOk}
            {...props}
        >
            <div style={{flexShrink: 0,}}>
                <Form css={cssForm} form={form} size='small' onFinish={handleFormFinishClick}>
                    <Row gutter={12}>
                        <Col span={18}>
                            <Form.Item name="keyword" label="关键词">
                                <Input placeholder="可输入界面视图代码、名称进行模糊查询" allowClear/>
                            </Form.Item>
                        </Col>

                        <Col span={6} style={{textAlign: 'right'}}>
                            <Button onClick={handleFormResetClick} style={{margin: '0 8px'}}>重置</Button>
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div style={{overflow: 'hidden', display: 'flex', flexDirection: 'column', background: '#fff', flexGrow: 1, padding: 0}}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    rowKey={"pkid"}
                    rowSelection={{...rowSelection}}
                />
            </div>
        </Modal>
    );
}
