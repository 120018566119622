import { ISchema } from '@formily/react'

export const DatePicker: ISchema = {
  type: 'object',
  properties: {
    clearable: {
      type: 'boolean',
      'x-decorator': 'FormItem',
      'x-component': 'Switch',
    },
    picker: {
      type: 'number',
      'x-decorator': 'FormItem',
      'x-component': 'Select',
      enum: [{label: '周选择', value: 'week'},
        {label: '月选择', value: 'month'},
        {label: '财年选择', value: 'quarter'},
        {label: '年选择', value: 'year'},
      ],
      "x-component-props": {
        allowClear: true,
        placeholder: '请选择'
      }
    },
    placeholder: {
      type: 'string',
      'x-decorator': 'FormItem',
      'x-component': 'Input',
      "x-component-props": {
        allowClear: true,
        placeholder: '请输入'
      }
    },
  },
}

