export const Tag = {
  'zh-CN': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: {
          title: '预设颜色',
          dataSource: [
            '品红',   // magenta
            '红色',   // red
            '火山红',// volcano
            '橙色',   // orange
            '金色',   // gold
            '酸橙绿',// lime
            '绿色',   // green
            '青色',   // cyan
            '蓝色',   // blue
            '极客蓝',// geekblue
            '紫色'    // purple
          ]
        },
        customColor: '自定义颜色',
        closable: '是否可以关闭',
        iconName: '图标'
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        title: '内容',
        color: '颜色'
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}
