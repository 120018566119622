import { ISchema } from '@formily/react'
import { SwiperSetter } from '../setters';

export const Swiper: ISchema = {
  type: "object",
  properties: {
    items: {
      type: "array",
      "x-decorator": "FormItem",
      "x-component": SwiperSetter,
    },
    height: {
      type: "string",
      "x-decorator": "FormItem",
      "x-component": "SizeInput",
      "x-component-props": {
        placeholder: "请输入高度",
        include: ['inherit', 'px', 'vh'],
      },
    },
    allowTouchMove: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultChecked: true,
      },
    },
    autoplay: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-component-props": {
        defaultValue: false,
      },
    },
    loop: {
      type: "boolean",
      "x-decorator": "FormItem",
      "x-component": "Switch",
      "x-reactions": {
        dependencies: [".autoplay"],
        fulfill: {
          state: {
            visible: "{{!!$deps[0]}}",
          },
        },
      },
    },
    autoplayInterval: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        min: 0,
        max: 10000,
        defaultValue: 3000,
      },
      "x-reactions": {
        dependencies: [".autoplay"],
        fulfill: {
          state: {
            visible: "{{$deps[0]}}",
          },
        },
      },
    },
    defaultIndex: {
      type: "number",
      "x-decorator": "FormItem",
      "x-component": "NumberPicker",
      "x-component-props": {
        defaultValue: 0,
      },
    },
    // direction: {
    //   type: "string",
    //   "x-decorator": "FormItem",
    //   "x-component": "Radio.Group",
    //   "x-component-props": {
    //     defaultValue: "horizontal",
    //   },
    //   enum: [
    //     {
    //       label: "水平",
    //       value: "horizontal",
    //     },
    //     {
    //       label: "垂直",
    //       value: "vertical",
    //     },
    //   ],
    // },
  },
};

