import React from 'react'
import { Input } from 'antd'
import { ClickEventSetter, IconSelect } from '../index'

export interface IClickInputProps {
  value?: any
  onChange?: (value: any) => void
  type?: 'input' | 'click' | 'icon',
  hideClick?: boolean
}

export const ClickInput: React.FC<IClickInputProps> = ({type, hideClick, ...props}) => {
  return (
    <>
      {
        type == 'input' &&
        <Input {...props} />
      }
      {
        type == 'click' &&
        <ClickEventSetter hideClick={hideClick} senceType='modal' {
          ...props
        } />
      }
      {
        type == 'icon' &&
        <IconSelect {...props} />
      }
    </>
  )
}
