export const Image = {
  'zh-CN': {
    title: '图片',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        noPreview: '是否禁止预览',
        alt: '图片描述',
        clickEventProps: '事件',
      },
    },
  },
  'en-US': {
    title: '图片',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
  'ko-KR': {
    title: '图片',
    settings: {
      'x-component-props': {
        src: '图片地址',
        width: "图片宽度",
        height: '图片高度',
        preview: '是否支持预览',
        alt: '图片描述'
      },
    },
  },
}
