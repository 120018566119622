export const Progress = {
  'zh-CN': {
    title: '进度条',
    settings: {
      'x-component-props': {
        percent: '百分比',
        showInfo: '显示数值或图标',
        status: '状态',
        type: {
          title: '类型',
          dataSource: [
            {
              label: '条',
              value: 'line',
            },
            {
              label: '圈',
              value: 'circle',
            },
            {
              label: '仪表盘',
              value: 'dashboard',
            },
          ],
        },
        width: '画布宽度',
        strokeWidth: '线宽',
        gapDegree: '缺口角度',
        gapPosition: '缺口位置',
      },
    },
  },
  'en-US': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
  'ko-KR': {
    title: '标签',
    settings: {
      'x-component-props': {
        offsetBottom: '底部偏移量',
        offsetTop: '顶部偏移量',
      },
    },
  },
}
