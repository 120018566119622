import { createBehavior, createResource } from "@designable/core";
import { AntdEllipsis, FormilyEllipsisProps } from "./Ellipsis"; 
import { DnFC } from "@designable/react";
import { createBaseSchema } from "../Field";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
// import "./styles.less";

export const Ellipsis: DnFC<FormilyEllipsisProps> =  AntdEllipsis;

Ellipsis.Behavior = createBehavior({
  name: "Ellipsis",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Ellipsis",
  designerProps: {
    propsSchema: createBaseSchema(AllSchemas.Ellipsis),
  },
  designerLocales: AllLocales.Ellipsis,
});

Ellipsis.Resource = createResource({
  icon: "EllipsisSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "void",
        "x-component": "Ellipsis",
        "x-component-props": {
          content: '软件逻辑地图是一款使用创新性“敏捷设计建模”技术的 软件设计建模系统， 应用在软件开发和软件运维中，可以快速构建和持续迭代一套在线化、结构化、可视化、可生成程序源码的集成式软件逻辑模型(ISLM)，集成了软件的业务逻辑、功能逻辑、算法逻辑和程序逻辑，替代各种“线下的分散的非结构化的”设计文档，帮助IT团队全面掌控软件逻辑 ，大幅降本增效、缩短交期、提升质量和化解风险!',
          "style" : {
            padding: '10px 10px 10px 10px'
          },
          expandText: '展开',
          collapseText: "收起"
        },
      },
    },
  ],
});